import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GraphqlConnectionService } from 'src/providers/graphql-connection/graphql-connection.service';
import { AndroidBackButtomService } from 'src/providers/navigation/android-back-button';
import { SessiondataService } from 'src/providers/sessiondata/sessiondata.service';
import { FindCabysServiceInput, FindCabysOutput } from 'src/constants/connections/cabys/cabys';
import { fetchCabysCodes } from 'src/constants/connections/cabys/queries';
import { Cabys } from 'src/types/Cabys';


@Component({
  selector: 'app-cabys-code',
  templateUrl: './cabys-code.page.html',
  styleUrls: ['./cabys-code.page.scss'],
})
export class CabysCodePage implements OnInit {

  cabys: Array<Cabys>= [];
  lang: any;
  searchTerm: string = '';
  hasItems: boolean = false;
  isLoading: boolean = false;
  hasInternet: boolean = true;
  failedSearch: boolean = false;
  cabyLang;
  emptyMessage;
  selectedRadioItem;

  constructor(private sessiondata: SessiondataService, public modalCtrl: ModalController, private connection: GraphqlConnectionService, private backButton: AndroidBackButtomService) { }

  ngOnInit() {
    this.lang = this.sessiondata.getJsonLanguages(this.sessiondata.currentlanguage);
    this.cabyLang = this.lang.CABYS;
    this.emptyMessage = this.failedSearch ? this.cabyLang.EMPTY_FAILED : this.cabyLang.EMPTY;
  }

  ionViewDidEnter() { 
    this.registerActionButton(); 
  }

  ionViewWillLeave() { 
    this.backButton.deregisterAction(); 
  }

  registerActionButton(){
    this.backButton.registerAction(()=>{
      this.backButton.goBack().then(
        () => this.goToSave(),
        error => console.log(error)
      );
    }, 100);
  }

  async findCabys(){
    await this.anotherFindCabys();
  }

  async anotherFindCabys(){
    const requestData : FindCabysServiceInput = {
      name: this.searchTerm
    }
    this.isLoading = true;
    try {
      const mutation = fetchCabysCodes(requestData) ;
      const response = await this.connection.post(mutation, true);
      if(response && response.data){
        const { getCabysServices } : any = response.data;
        const { success, data } : FindCabysOutput =  getCabysServices;
        if(success && data){
          this.cabys = data;
        }else{
          this.failedSearch = true;
        }
        this.hasItems = this.cabys.length > 0;
        this.isLoading = false;
        this.emptyMessage = this.failedSearch ? this.cabyLang.EMPTY_FAILED : this.cabyLang.EMPTY;
      }
      this.isLoading = false;
    }catch(e){
      this.hasItems = false;
      this.isLoading = false;
      this.failedSearch = true;
      this.emptyMessage = this.failedSearch ? this.cabyLang.EMPTY_FAILED : this.cabyLang.EMPTY;
    }
  }

  goToSave(){
    this.modalCtrl.dismiss({
      cabys: this.selectedRadioItem
    });
  }

  clear(){
    this.failedSearch = false;
    this.emptyMessage = this.failedSearch ? this.cabyLang.EMPTY_FAILED : this.cabyLang.EMPTY;
  }

  viewTextChange(){
    if(this.searchTerm === ""){
      this.failedSearch = false;
      this.emptyMessage = this.failedSearch ? this.cabyLang.EMPTY_FAILED : this.cabyLang.EMPTY;
    }
  }

  redirectOnboard(){
    this.modalCtrl.dismiss({
      cabys: ''
    });
  }
}
