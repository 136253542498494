import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';


import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Camera } from '@ionic-native/camera/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';

import { ReactiveFormsModule } from '@angular/forms';

import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AlertService } from 'src/providers/alert/alert.service';
import { AuthGuardService } from 'src/providers/authGuard/auth-guard.service';
import { IonicStorageModule } from '@ionic/storage';
import { FilterListProvider } from 'src/providers/filter-list/filter-list';
import { pageTransition } from './page-transition';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Vibration } from '@ionic-native/vibration/ngx';

import { AppMaterialModule } from './app.material.module';

import { Chooser } from '@ionic-native/chooser/ngx';

import { Downloader} from '@ionic-native/downloader/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { DocumentViewer } from '@ionic-native/document-viewer/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    IonicStorageModule.forRoot({
      name: '__ft',
      driverOrder: ['indexeddb','websql',, 'sqlite']
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    IonicModule.forRoot({
      backButtonText: '',
      navAnimation: pageTransition
    }),
    AppRoutingModule,
    AppMaterialModule,
    BrowserAnimationsModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-CR' },
    StatusBar,
    SplashScreen,
    Camera,
    InAppBrowser,
    HttpClient,
    HTTP,
    AlertService,
    AuthGuardService,
    FilterListProvider,
    AndroidPermissions,Chooser,Vibration,
    Downloader,
    DocumentViewer,
    Keyboard, 
    FileOpener,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    FileTransfer
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
}
