import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sent-invoice-data',
  templateUrl: './sent-invoice-data.component.html',
  styleUrls: ['./sent-invoice-data.component.scss'],
})
export class SentInvoiceDataComponent implements OnInit {

  @Input('invoice') invoice: any;

  @Input('show') show: boolean;

  @Input('langData') langData: any;

  @Input('status') status: any;

  @Input('type') type: any;

  constructor() { 
    
  }

  ngOnInit() {
    console.log("status que llega "+JSON.stringify(this.status))
  }

}
