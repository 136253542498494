import { AddProductDataInput, UpdateProductDataInput } from "./product";

function returnedStock(stock){
    let myStock = ''
    stock.forEach(stockC=>{
        myStock += `{
            IDAgency: "${stockC.IDAgency}"
            stock: ${stockC.stock}
        }`
    });
    return myStock;
}

export function addProductData(product: AddProductDataInput){
    const {
        isService,
        price,
        currency,
        name,
        description,
        measureUnit,
        measureDetail,
        agriculturalInput,
        shareUrl,
        imageUrl,
        isProduct,
        CABYScode,
        stock,
        taxRate
    } = product;
    
    const currentStock = returnedStock(stock);

    return `mutation {
        addProductData(
            addProductData: {
                CABYScode: "${CABYScode}"
                isService : ${isService}
                price: ${price}
                currency: "${currency}"
                name: "${name}"
                description:  "${description}"
                measureUnit: "${measureUnit}"
                measureDetail:  "${measureDetail}"
                agriculturalInput: ${agriculturalInput}
                shareUrl:  "${shareUrl}"
                imageUrl:  "${imageUrl}"
                isProduct: ${isProduct}
                stock: [${currentStock}]
                taxRate: "${taxRate}"
            })
            { 
                success
                message
                description
                code
                data{
                    _id
                    IDUser
                    isService
                    isProduct
                    currency
                    price
                    name
                    description
                    measureUnit
                    measureDetail
                    agriculturalInput
                    shareUrl
                    imageUrl
                    stock {
                        IDAgency
                        stock
                    }
                    CABYScode
                    taxRate
                }
            }
    }`
}

export function updateProductData(updatedProduct: UpdateProductDataInput){

    const { 
        idProduct,
        product
    } = updatedProduct;

    const {
        isService,
        price,
        currency,
        name,
        description,
        measureUnit,
        measureDetail,
        agriculturalInput,
        shareUrl,
        imageUrl,
        isProduct,
        CABYScode,
        stock,
        taxRate
    } = product;

    const currentStock = returnedStock(stock);
    return `mutation {
        updateProductData(
            updateProductData: {
                idProduct: "${idProduct}"
                product:{ 
                    CABYScode: "${CABYScode}"
                    isService : ${isService}
                    price: ${price}
                    currency: "${currency}"
                    name: "${name}"
                    description:  "${description}"
                    measureUnit: "${measureUnit}"
                    measureDetail:  "${measureDetail}"
                    agriculturalInput: ${agriculturalInput}
                    shareUrl:  "${shareUrl}"
                    imageUrl:  "${imageUrl}"
                    isProduct: ${isProduct}
                    stock: [${currentStock}]
                    taxRate: "${taxRate}"
                }
            })
            { 
                success
                message
                description
                code
                data{
                    _id
                    IDUser
                    isService
                    isProduct
                    currency
                    price
                    name
                    description
                    measureUnit
                    measureDetail
                    agriculturalInput
                    shareUrl
                    imageUrl
                    stock {
                        IDAgency
                        stock
                    }
                    CABYScode
                    taxRate
                }
            }
    }`
}

export function deleteProductByCode(idProducto){
    return `mutation {
        deleteProduct(deleteProductData : {
            idProduct : "${idProducto}"
            }){
                success
                message
                code
                description
            }
        }`
}

export function getProducts(){
    return `
        query{
            getUserProducts
            {
                success
                message
                description
                code
                data{
                    _id
                    IDUser
                    isService
                    isProduct
                    currency
                    price
                    name
                    description
                    measureUnit
                    measureDetail
                    agriculturalInput
                    shareUrl
                    imageUrl
                    stock {
                        IDAgency
                        stock
                    }
                    CABYScode
                    taxRate
                }
            }
        }
    `
}


export function getImportantValues(){
    return  `
        query {
            getTaxRates {
                success
                message
                description
                data {
                    name
                    code
                }
            }
        
            getMeasureUnits {
                success
                message
                description
                data {
                    symbol
                    description
                }
            }

            getUserAgencies
            {
                success
                message
                description
                code
                data{
                    _id
                    IDUser
                    agencyName
                    address{
                        country
                        province
                        address
                    }
                    phone{
                        e164
                        nationalNumber
                        countryCode
                    }
                }
            }
        }
    `
}

