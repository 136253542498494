import { Component, Input, OnInit, } from '@angular/core';


@Component({
  selector: 'app-empty-list',
  templateUrl: './empty-list.component.html',
  styleUrls: ['./empty-list.component.scss'],
})
export class EmptyListComponent implements OnInit {

  isImage: boolean = true;

  @Input('image') image: any;
  @Input('icon') icon: any;
  @Input('title') title: any;
  @Input('text') text: any;

  constructor() { }

  ngOnInit() {
    this.isImage = this.image && this.image !== '';
  }

}
