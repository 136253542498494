import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-image-profile-selector',
  templateUrl: './image-profile-selector.component.html',
  styleUrls: ['./image-profile-selector.component.scss'],
})
export class ImageProfileSelectorComponent implements OnInit {

  @Input('clickedImage') clickedImage: string;
  @Input('icon') icon : string;
  
  @Output() OnClick = new EventEmitter<any>();

  isVisible = false;

  constructor() { }

  ngOnInit() {}

  choosePhoto(){
    this.OnClick.emit();
    this.isVisible = this.clickedImage !== ""
  }

  ngDoCheck(){
    this.isVisible = this.clickedImage !== ""
  }
}
