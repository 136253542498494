import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit {

  @Input('steps') steps: Array<any>;
  @Input('currentStep') currentStep: number = -1;

  @Output() updatedData = new EventEmitter<any>();

  editableSteps : Array<any> = [];

  constructor() { }

  ngOnInit() {
    this.editableSteps = [...this.steps];
  }

  ngOnChanges(){
    this.editableSteps = [...this.steps];
    if(this.currentStep > -1){
      this.setActive(this.currentStep);
      this.setTime(this.currentStep);
    }
  }

  setTime(count){
    let time = this.editableSteps[count].checkpoint;
    if(time < 1){
      let timerId = setInterval(()=> {
        if(time >= 1){
          this.editableSteps[count].checkpoint = 1;
          this.editableSteps[count].done = true;
          clearInterval(timerId)
          this.updatedData.emit(this.editableSteps[count]);
        }else{
          time += 0.1;
          this.editableSteps[count].checkpoint = time;
        }
      },50)
    }
  }

  setActive(count){
    setTimeout(()=>{
      this.editableSteps[count].active = true;
    },300)
  }

}
