import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { LoginAuthService } from '../loginAuth/login-auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private router: Router, private login: LoginAuthService) {
    
  }

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise(async (resolve) => {
      const isLoggedIn: boolean = await this.login.isLoggedIn();
      if (!isLoggedIn) {
        this.router.navigate(["onboardscreen"]);
        resolve(false);
      }
      resolve(true);
    });
  }
}
