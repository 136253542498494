import { ChangeDetectorRef, Component, Output, ViewRef } from "@angular/core";
import { EventEmitter } from "@angular/core";

@Component({
    selector: 'verify-number-input',
    templateUrl: './verify-number-input.component.html',
    styleUrls: ['./verify-number-input.component.scss']
})
export class VerifyNumberInput {

    @Output() submittedCodeEvent: EventEmitter<string> = new EventEmitter<string>();

    codePresent = "------";
    lbl_pos1 = "-";
    lbl_pos2 = "-";
    lbl_pos3 = "-";
    lbl_pos4 = "-";
    lbl_pos5 = "-";
    lbl_pos6 = "-";
    code = "";
    
    constructor(
        public cdRef: ChangeDetectorRef){

    }

  validarCampo(number){
    if(isNaN(parseInt(number[number.length-1]))) {
      setTimeout(()=>{
        this.code = number.slice(0, number.length-1);
        this.codePresent = this.makeNumber(this.code);
        if (!(<ViewRef>this.cdRef).destroyed) {
          this.cdRef.detectChanges();
        }
      },10)
    } else {
      setTimeout(()=>{
        this.code = number;
        this.codePresent = this.makeNumber(this.code);
        if (!(<ViewRef>this.cdRef).destroyed) {
          this.cdRef.detectChanges();
        }
      },10)
    }   
  }

  makeNumber(code) : string {
    this.codePresent = "------";
    switch(code.length){
      case 6 :  
      this.lbl_pos6 = code.charAt(5);
      return code.slice(0,5) + code.charAt(code.length -1 );  
      case 5 :  
      this.lbl_pos5 = code.charAt(4);
      this.lbl_pos6 = "-";
      return code.slice(0,4) + code.charAt(code.length -1 ) + "-";  
      case 4 :  
      this.lbl_pos4 = code.charAt(3);
      this.lbl_pos5 = "-";
      this.lbl_pos6 = "-";
      return code.slice(0,3) + code.charAt(code.length -1 ) + "--";  
      case 3 :  
      this.lbl_pos3 = code.charAt(2);
      this.lbl_pos4 = "-";
      this.lbl_pos5 = "-";
      this.lbl_pos6 = "-";
      return code.slice(0,2) + code.charAt(code.length -1 ) + "---";
      case 2 :  
      this.lbl_pos2 = code.charAt(1);
      this.lbl_pos3 = "-";
      this.lbl_pos4 = "-";
      this.lbl_pos5 = "-";
      this.lbl_pos6 = "-";
      return code.slice(0,1) + code.charAt(code.length -1) + "----";
      case 1 :  
      this.lbl_pos1 = code.charAt(0);
      this.lbl_pos2 = "-";
      this.lbl_pos3 = "-";
      this.lbl_pos4 = "-";
      this.lbl_pos5 = "-";
      this.lbl_pos6 = "-";
      return code.charAt(code.length - 1 ) + "-----" ;
    }

    if(this.code === "") 
    this.lbl_pos1 = "-";
    this.lbl_pos2 = "-";
    this.lbl_pos3 = "-";
    this.lbl_pos4 = "-";
    this.lbl_pos5 = "-";
    this.lbl_pos6 = "-";
    return "------";
  }

  async verifyNumber(){
      this.submittedCodeEvent.emit(this.code);
  }


}