export function getMeasureUnitsService(){
    return `
        query{
            getProductUnits
            {
                success
                message
                description
                code
                data{
                    symbol
                    description
                }
            }
        }
    `
}

export function getServiceUnitsTypes(){
    return `
    query {
        getServiceUnits{
          success
          message
          description
          code
          data{
            symbol
            description
          }
        }
      }
    `
}