import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-presenter-button',
  templateUrl: './presenter-button.component.html',
  styleUrls: ['./presenter-button.component.scss'],
})
export class PresenterButtonComponent implements OnInit {

  @Input('title') title: any;

  @Input('text') text: any;

  @Input('customClass') customClass: string;

  
  @Input('titleClass') titleClass: string;
  
  @Input('customTextClass') customTextClass: string;
  
  @Output() OnClick = new EventEmitter<any>();


  constructor() { }

  ngOnInit() {
  }

  onClick(){
    this.OnClick.emit();
  }

}
