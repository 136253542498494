import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Agency, GetUserAgenciesOutput } from 'src/constants/connections/agency/agency';
import { getAgencies } from 'src/constants/connections/agency/queries';
import { AlertService } from 'src/providers/alert/alert.service';
import { FilterListProvider } from 'src/providers/filter-list/filter-list';
import { GraphqlConnectionService } from 'src/providers/graphql-connection/graphql-connection.service';
import { AndroidBackButtomService } from 'src/providers/navigation/android-back-button';
import { SessiondataService } from 'src/providers/sessiondata/sessiondata.service';
import { TimeProviderService } from 'src/providers/time-provider/time-provider.service';
import { NewAgencyPage } from '../new-agency/new-agency.page';

@Component({
  selector: 'app-agencies',
  templateUrl: './agencies.page.html',
  styleUrls: ['./agencies.page.scss'],
})
export class AgenciesPage implements OnInit {

  @Input() from: number;
  @Input() choosen: any;

  agency: Array<Agency> = []
  lang;
  agencyUnmute: Array<any> = [];
  selectedRadioItem;

  searchTerm: string = '';
  hasItems: boolean = false;
  isLoading: boolean = false;
  hasInternet: boolean = true;

  constructor(
    public filterList: FilterListProvider,
    public modalCtrl: ModalController,
    private router : Router,
    private alertService: AlertService,
    private sessionData: SessiondataService,
    private connection: GraphqlConnectionService,
    private timeValidate: TimeProviderService,
    private backButton: AndroidBackButtomService,
    private location: Location
  ) { }

  ngOnInit() {
    this.lang = this.sessionData.getJsonLanguages(this.sessionData.currentlanguage);
  }


  ionViewDidEnter() { 
    this.registerActionButton(); 
  }

  registerActionButton(){
    this.backButton.registerAction(()=>{
      this.backButton.goBack().then(
        () => (this.from === 1) ? this.redirectOnboard() : this.backButton.goBackLocation(this.location),
        error => console.log(error)
      );
    }, 100);
  }

  setFilteredItems(){
    this.agency = this.filterList.filterItems(this.searchTerm,'agencyName',this.agencyUnmute);
  }

  redirectOnboard(agency = null){
    if(this.from === 1){
      this.modalCtrl.dismiss({
        agency
      });
    }else{
      this.router.navigate(['home-dash-board']);
    }
  }

  ionViewWillLeave(){
    this.sessionData.setData('agencylist',[...this.agencyUnmute]);
    this.backButton.deregisterAction(); 
  }

  async ionViewWillEnter(){
    this.isLoading = true;
    try{
      const time : any = await this.sessionData.getData('lastTimeAgencyList');
      if(time){
        console.log(this.choosen)
        this.selectedRadioItem = this.choosen || '';
        const validated = this.timeValidate.isLessThanXMins(new Date(), new Date(time), 30);
        validated ? this.getBackendAgencies() : this.getLocalAgencies()
      }else{
        this.getBackendAgencies();
      }
    }catch(error){
      this.getBackendAgencies();
    }
  }

  async openAgency(agency=null,from=true, index=-1){
    const modal = await this.modalCtrl.create({
      component: NewAgencyPage,
      cssClass: 'normal-modal',
      componentProps: {
        from,
        agency
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if(data && data.agency){
      const agency: Agency = data.agency;

      switch(data.action){
        case "add": this.addAgency(agency); break;
        case "edit": this.editAgency(agency,index); break;
        case "delete": this.deleteAgency(index); break;
      }
    }
  }

  radioSelect(agency: Agency){
    this.modalCtrl.dismiss({
      agency: agency.agencyName,
      id: agency._id
    });
  }

  addAgency(agency){
    this.agency.push(agency);
    this.agencyUnmute.push(agency);
    this.hasItems = this.agencyUnmute.length > 0;
    this.sessionData.setData('agencylist',[...this.agencyUnmute]);
  }

  editAgency(client,index){
    this.agency[index] = {...client};
    this.agencyUnmute[index] = {...client};
    this.hasItems = this.agencyUnmute.length > 0;
    this.sessionData.setData('agencylist',[...this.agencyUnmute]);
  }

  deleteAgency(index){
    this.agency.splice(index,1);
    this.agencyUnmute.splice(index,1);
    this.hasItems = this.agencyUnmute.length > 0;
    this.sessionData.setData('agencylist',[...this.agencyUnmute]);
  }

  //code 17-1-2021

  async getBackendAgencies(){
    const query = getAgencies();
    try{
      const response = await this.connection.post(query, true);

      this.isLoading = false;
      if(response.data){
        const { getUserAgencies } : any = response.data;
        const { success, data, message } : GetUserAgenciesOutput = getUserAgencies;
        if( success && data ){
          const list: Array<Agency> = data;
          console.log(list)
          this.agency = [...list];
          this.agencyUnmute= [...list];
          this.hasItems = this.agencyUnmute.length > 0;
          this.sessionData.setData('lastTimeAgencyList',Date.now());
        }
      }else{
        this.alertService.presentToast(this.lang.AGENCY.ERRORBACKENDGET); //TODO response.errors
      } 
    }catch(e){
      this.isLoading = false;
      this.alertService.presentToast(this.lang.AGENCY.ERRORBACKENDGET);
    }
  }

  async getLocalAgencies(){
    try{
      const list: any = await this.sessionData.getData('agencylist');
      if(list){
        this.agency = [...list];
        this.agencyUnmute= [...list];
        this.hasItems = this.agencyUnmute.length > 0;
      }else{
        this.agency = [];
        this.agencyUnmute= [];
        this.hasItems = false;
      }
      this.isLoading = false;
    }catch(e){
      this.agency = [];
      this.agencyUnmute= [];
      this.hasItems = false;
      this.isLoading = false;
    }
  }
}
