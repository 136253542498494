import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';


@Component({
  selector: 'app-calendar',
  templateUrl: 'calendar.html',
  styleUrls:[ 'calendar.scss' ]
})
export class CalendarComponent {

  @Input('classDiv') classDiv: string;
  @Input('minDate') minDate: Date;
  @Input('maxDate') maxDate: Date;
  @Input('label') label: any;


  @Output('ClickAction') ClickAction = new EventEmitter<any>();
  @Output('ChangeActionDate') ChangeActionDate = new EventEmitter<any>();
  @ViewChild('input', { read: MatInput}) input: MatInput;

  constructor() {}

  changeActionDate($e){ this.ChangeActionDate.emit({model: $e, input: this.input}); }
  clickAction(picker?){ this.ClickAction.emit(picker); }

  cleanDate(){
    this.input.value="";
   }
  
}
