export const currencies = [
    {
        "value": "Colón",
        "label": "Colón",
        "code": 'CRC',
    },
    {
        "value": "Dólar",
        "label": "Dólar",
        "code": 'USD',
    },
    {
        "value": "Euro",
        "label": "Euro",
        "code": 'EUR',
    }
]