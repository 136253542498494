import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { AddAgencyInput, AddAgencyOutput, Agency, AgencyAddressTypeInput, AgencyPhoneNumberInput, DeleteAgencyOutput } from 'src/constants/connections/agency/agency';
import { addAgency, deleteAgency, updateAgency } from 'src/constants/connections/agency/queries';
import { AlertService } from 'src/providers/alert/alert.service';
import { ConnectionManagerService } from 'src/providers/connection/connection-manager.service';
import { GraphqlConnectionService } from 'src/providers/graphql-connection/graphql-connection.service';
import { AndroidBackButtomService } from 'src/providers/navigation/android-back-button';
import { PlacesService } from 'src/providers/places/places.service';
import { SessiondataService } from 'src/providers/sessiondata/sessiondata.service';
import { InputValidators } from 'src/providers/validators/inputvalidators';

@Component({
  selector: 'app-new-agency',
  templateUrl: './new-agency.page.html',
  styleUrls: ['./new-agency.page.scss'],
})
export class NewAgencyPage implements OnInit {


  lang;
  formGroup;

  agc_country: FormControl;
  agc_state: FormControl;
  agc_address: FormControl;
  agc_name: FormControl;
  agc_phone: FormControl;

  hasErrorCountry;
  hasErrorState;


  selectedCountry = '';
  selectedState = '';

  buttons= [];
  provinces: any = [];
  countries = [
    {
      label: 'Costa Rica',
      value: 'Costa Rica',
      code: '506'
    }
  ];

  isLoading= false;
  fullCountry;


  @Input() from: boolean;
  @Input() agency: Agency;

  constructor(
    private sessiondata: SessiondataService,
    private connection: GraphqlConnectionService,
    private alertService: AlertService,
    private modalCtrl: ModalController,
    public connManager: ConnectionManagerService,
    private formBuilder: FormBuilder,
    private places: PlacesService,
    private backButton: AndroidBackButtomService,
  ) { }

  async ngOnInit() {
    this.lang = this.sessiondata.getJsonLanguages(this.sessiondata.currentlanguage);
    this.formConstructor();
    this.buttons = [
      {
        text: this.lang.BUTTONS.BTNCANCEL,
        role: 'cancel',
        cssClass: 'btnModalDismiss'
      }, {
        text: this.lang.BUTTONS.BTNACCEPT,
        cssClass: 'btnModalAcept'
      }
    ];

    const provs = await this.places.getCRCProvinces();
    this.provinces = this.places.createRadioPlaces(provs,'province','key','code');
  }

  ionViewDidEnter() { 
    this.registerActionButton(); 
  }

  ionViewWillLeave() { 
    this.backButton.deregisterAction(); 
  }

  registerActionButton(){
    this.backButton.registerAction(()=>{
      this.backButton.goBack().then(
        () => this.redirectPage(),
        error => console.log(error)
      );
    }, 100);
  }

  formConstructor(){

    let address: any = {};
    let phone:any = {};
    let agencyName: any = "";

    if(this.agency){
      address = this.agency.address;
      phone = this.agency.phone;
      agencyName = this.agency.agencyName;
      this.selectedCountry = address.country;
      this.fullCountry = this.countries.find(country =>  country.value == this.selectedCountry );

    }

    this.agc_country = new FormControl(address.country || '', [Validators.required]);
    this.agc_state = new FormControl(address.province|| '', [Validators.required]);
    this.agc_address = new FormControl(address.address || '', [Validators.required]);
    this.agc_name = new FormControl(agencyName || '', [Validators.required]);
    this.agc_phone = new FormControl(phone.nationalNumber || '', [Validators.required, InputValidators.phoneNumerValidator]);


    this.formGroup = this.formBuilder.group({
      agc_country: this.agc_country,
      agc_state: this.agc_state,
      agc_address: this.agc_address,
      agc_name: this.agc_name,
      agc_phone: this.agc_phone,
    })
  }


  async countryAlert() {
    try{
      const response: any = await this.alertService.showListAlert( this.lang.ALERT.PHONENUMBER.TITLE, this.buttons,'customAlert',this.countries, this.selectedCountry);
      if(response){
        this.fullCountry = this.countries.find(e => e.value.includes(response))
        this.setType('agc_country', this.fullCountry.label)
        this.selectedCountry =  this.fullCountry.label;
        this.hasErrorCountry = false;
      }
      else{
        this.hasErrorCountry = this.selectedCountry === '';
      }
    }catch(e){
      console.log(e)
      this.hasErrorCountry = this.selectedCountry === '';
    }
  }

  async stateAlert() {
    try{
      const response: any = await this.alertService.showListAlert( this.lang.ALERT.CITY, this.buttons,'customAlert',this.provinces, this.getType('agc_state'));
      if(response){
        const fullState = this.provinces.find(e => e.value.includes(response))
        this.selectedState = fullState.label
        this.setType('agc_state',fullState.label)
        this.hasErrorState = false;
      }
      else{
        console.log(this.selectedState)
        this.hasErrorState = this.getType('agc_state') === '';
      }
    }catch(e){
      console.log(this.selectedState)
      this.hasErrorState = this.getType('agc_state') === '';
    }
  }

  getType(type){
    return this.formGroup.controls[type].value;
  }

  setType(type,value){
    this.formGroup.controls[type].setValue(value);
  }

  createRadios(array) {
    const theNewInputs = [];
    for (var element of Object.keys(array)) {
      theNewInputs.push(
        {
          type: 'radio',
          label: array[element],
          value: array[element],
          checked: false,
          key: element
        }
      );
    }
    return theNewInputs;
  }


  getMessageError(value,type?){
    const forms =  this.lang.FORMS;

    if(!!value.errors){
      const { required, pattern } = value.errors
      if(required){
        return forms.REQUIRED;
      }else if(!!pattern){
        return (type === 'phone') ? forms.PHONE : forms. REQUIRED;
      } else if(value.errors?.invalidPhone){
        return forms.PHONE;
      }
    }
  }

  async goToDelete(){
    this.isLoading = true;
    const { ERRORS } = this.lang;
    try{
      const mutation = deleteAgency({
        agencyID: this.agency._id
      })
      const response = await this.connection.post(mutation, true);
      this.isLoading = false;
      if(response && response.data){
        const { deleteUserAgency } : any = response.data;
        const { success, message } : DeleteAgencyOutput = deleteUserAgency;
        if(success){
          this.alertService.presentToast(message, 'primary');
          this.redirectPage(this.agency, 'delete');
        }else{
          this.alertService.presentToast(message);
        }
      }else{
        this.alertService.presentToast(ERRORS.DELETEAGENCY);
      }
    }catch(e){
      this.alertService.presentToast(ERRORS.DELETEAGENCY);
    }
  }

  async goToSave(){
    this.isLoading = true;
    const agency = this.getAgency();
    const { ERRORS } = this.lang;
    try {
      debugger;
      const mutation = this.from ? addAgency(agency): updateAgency(agency);
      const response = await this.connection.post(mutation, true);
      this.isLoading = false;
      if(response && response.data){
        const { addAgency, updateUserAgency } : any = response.data;
        const { success, data, message } : AddAgencyOutput = this.from ? addAgency: updateUserAgency;
        if(success && data){
          this.alertService.presentToast(message, 'primary');
          const savedAgency : Agency = data;
          this.redirectPage(savedAgency,  this.from ? 'add' : 'edit');
        }else{
          this.alertService.presentToast(message);
        }
      }else{
        this.alertService.presentToast( this.from ? ERRORS.CREATINGAGENCY: ERRORS.UPDATEAGENCY);
      }
    }catch(e){
      this.alertService.presentToast( this.from ? ERRORS.CREATINGAGENCY: ERRORS.UPDATEAGENCY);
      this.isLoading = false;
    }
  }

  redirectPage(agency = null, action = 'add'){
    this.modalCtrl.dismiss({
      agency,
      action
    })
  }


  getAgency(){

    const address : AgencyAddressTypeInput = {
      country:  this.getType('agc_country') ,
      province: this.getType('agc_state') ,
      address:  this.getType('agc_address')  
    }

    const phone : AgencyPhoneNumberInput = {
      e164: `+${this.fullCountry.code}${this.getType('agc_phone')}`,
      nationalNumber: this.getType('agc_phone'),
      countryCode: this.fullCountry.code
    }


    const agency : AddAgencyInput = {
        address,
        phone,
        agencyName: this.getType('agc_name')  
    }
    return this.from ? agency : {
      ...agency,
      _id: this.agency._id
    };
  }


}
