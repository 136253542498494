import { UpdateDocumentStatusInput } from './inbox'

export function getUserMailBoxF(){
    return `query {
        getUserMailBox {
            success
            message
            description
            data{
                IDUser
                key
                consecutive
                issuedDate
                issue
                issuer
                receiver
                status
                atvStatus
                type{
                  code
                  name
                }
                attachements{
                  xmlInvoiceUrl
                  xmlResponseInvoiceUrl
                  pdfInvoiceUrl
                }
            }
        }
    }`
} 

export function updateDocumentStatus(data:UpdateDocumentStatusInput){
    var result = `mutation{
        updateDocumentStatus(updateDocumentData:{
          documentID:"${data.documentID}",
          spendingType:"${data.spendingType}",
          economicActivity:"${data.economicActivity}",
          documentStatus: ${data.documentStatus.toUpperCase()}
        }){
          code
          message
          description
          success
          data{
              xmlDoc
          pdfDoc
          updatedAt
          spendingType
          economicActivitie
          state
          createdAt
          updatedAt
          IDUser
          emissionDate
          receptionDate
          receiver
            }
        }
      }`;

    return result;
} 