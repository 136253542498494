import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { GetUserProductsOutput, Product } from 'src/constants/connections/product/product';
import { getProducts } from 'src/constants/connections/product/queries';
import { FilterListProvider } from 'src/providers/filter-list/filter-list';
import { GraphqlConnectionService } from 'src/providers/graphql-connection/graphql-connection.service';
import { AndroidBackButtomService } from 'src/providers/navigation/android-back-button';
import { SessiondataService } from 'src/providers/sessiondata/sessiondata.service';
import { TimeProviderService } from 'src/providers/time-provider/time-provider.service';
import { AgenciesPage } from '../agencies/agencies.page';
import { NewProductPage } from '../new-product/new-product.page';

@Component({
  selector: 'app-products',
  templateUrl: './products.page.html',
  styleUrls: ['./products.page.scss'],
})
export class ProductsPage implements OnInit {

  lang;
  category = "";
  warehouse= "";
  @Input() from: number;

  hasItems: boolean = false;
  hasItemsFilterList: boolean = true;
  isLoading: boolean = false;
  hasInternet: boolean = true;
  products: Array<any> = []
  searchTerm: string = '';
  productsUnmute: Array<Product> = [];
  nameAgency = ''

  constructor(public filterList: FilterListProvider,
    public modalCtrl: ModalController,
    private router : Router,
    private connection: GraphqlConnectionService,
    private timeValidate: TimeProviderService,
    private session: SessiondataService,
    private backButton: AndroidBackButtomService,
    private location: Location) { }

  ngOnInit() {
    this.lang = this.session.languageMessage;
  }

  ionViewDidEnter() { 
    this.registerActionButton(); 
  }

  registerActionButton(){
    this.backButton.registerAction(()=>{
      this.backButton.goBack().then(
        () => (this.from === 1) ? this.redirectOnboard() : this.backButton.goBackLocation(this.location),
        error => console.log(error)
      );
    }, 100);
  }

  setFilteredItems(){

      this.products = this.filterList.filterItems(this.searchTerm,'name',this.productsUnmute);
      this.hasItemsFilterList = this.products.length > 0;
  }

  clearAgency(){
    this.products = [...this.productsUnmute];
    this.hasItemsFilterList = this.products.length > 0;
    this.nameAgency = '';
  }

  async selectAgency(){
    const modal = await this.modalCtrl.create({
      component: AgenciesPage,
      cssClass: 'normal-modal',
      componentProps: {
        'from': 1,
        'choosen':  this.nameAgency
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    debugger
    if(data){
      const agency: any = data.agency;
      const id : any = data.id
      if(!!agency ){
        this.nameAgency= agency;
        const arrayOfPosbileStock = []
        this.productsUnmute.forEach(product=>{
            const posibleStock = product.stock.find(stock => stock.IDAgency == id);
            if(posibleStock)
              arrayOfPosbileStock.push(product)
        })

        this.products = [...arrayOfPosbileStock]
      }
    }
    this.hasItemsFilterList = this.products.length > 0;
  }

  async createProduct(product=null,from=true, index=-1){
    const modal = await this.modalCtrl.create({
      component: NewProductPage,
      cssClass: 'normal-modal',
      componentProps: {
        from,
        product,
        numberProduct: index
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if(data && data.product){
      const lastProduct: Product = data.product;
      switch(data.action){
        case "add": this.addProduct(lastProduct); break;
        case "edit": this.editProduct(lastProduct,index); break;
        case "delete": this.deleteProduct(index); break;
      }
    }
  }

  addProduct(product){
    this.products.push(product);
    this.productsUnmute.push(product);
    this.hasItems = true;
    this.session.setData('productslist',[...this.productsUnmute]);
  }

  editProduct(product,index){
    console.log(product)
    this.products[index] = {...product};
    this.productsUnmute[index] = {...product};
    this.hasItems = true;
    this.session.setData('productslist',[...this.productsUnmute]);
  }

  deleteProduct(index){
    this.products.splice(index,1);
    this.productsUnmute.splice(index,1);
    this.hasItems = this.productsUnmute.length > 0;
    this.session.setData('productslist',[...this.productsUnmute]);
  }

  ionViewWillLeave(){
    this.session.setData('productslist',[...this.productsUnmute]);
    this.backButton.deregisterAction(); 
  }

  openProduct(product,index){
    if(this.from === 1){
      this.modalCtrl.dismiss({
        product
      });
    }else{
      this.createProduct(product,false,index);
    }
  }

  async getBackendProducts(){
    const query = getProducts();
    try{
      const response = await this.connection.post(query, true);
      this.isLoading = false;
      if(response.data){
        const { getUserProducts } : any = response.data;
        const { success, data } : GetUserProductsOutput = getUserProducts;
        if( success && data ){
          const list: Array<Product> = data;
          this.products = [...list];
          this.productsUnmute= [...list];
          this.hasItems = this.productsUnmute.length > 0;
          this.session.setData('lastTimeProductsList',Date.now());
        }else{
          this.hasItems =false 
        }
      }else{
        this.hasItems =false      
      } 
    }catch(e){
      this.isLoading = false;
      this.hasItems =false  
    }
  }

  async getLocalProducts(){
    try{
      const list: any = await this.session.getData('productslist');
      if(list.length > 0 ){
        this.products = [...list];
        this.productsUnmute= [...list];
        this.hasItems = this.productsUnmute.length > 0;
      }else{
        this.products = [];
        this.productsUnmute= [];
        this.hasItems = false;
      }
      this.isLoading=false;
    }catch(e){
      this.products = [];
      this.productsUnmute= [];
      this.hasItems = false;
      this.isLoading = false;
    }

  }


  async ionViewWillEnter(){
    this.isLoading = true;
    try{
      const time : any = await this.session.getData('lastTimeProductsList');
      if(time){
        //this.selectedRadioItem = this.choosen || '';
        const validated = this.timeValidate.isLessThanXMins(new Date(), new Date(time), 30);
        validated ? this.getBackendProducts() : this.getLocalProducts()
      }else{
        this.getBackendProducts();
      }
    }catch(error){
      this.getBackendProducts();
    }
  }

  redirectOnboard(product = null){
    if(this.from === 1){
      this.modalCtrl.dismiss({
        product
      });
    }else{
      this.router.navigate(['home-dash-board']);
    }
  }

}
