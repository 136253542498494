import { NgModule } from '@angular/core';
import { GestureDirective } from './gestures/gestures.directive';
import { OnlyNumberDirective } from './only-number/only-number.directive';
import { ParticlesDirective } from './particles/particles.directive';

@NgModule({
	declarations: [ParticlesDirective, OnlyNumberDirective, GestureDirective],
	imports: [],
	exports: [ParticlesDirective, OnlyNumberDirective, GestureDirective]
})
export class DirectivesModule {}
