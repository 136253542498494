import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AgenciesPage } from 'src/pages/agencies/agencies.page';
import { SessiondataService } from 'src/providers/sessiondata/sessiondata.service';

@Component({
  selector: 'app-agency-row',
  templateUrl: './agency-row.component.html',
  styleUrls: ['./agency-row.component.scss'],
})
export class AgencyRowComponent implements OnInit {

  @Input() id: any;
  @Input() agency: string;
  @Input() IDAgency: string;
  @Input() myStock: string;
  @Input() currentArray: Array<any>;
  @Output() OnClick = new EventEmitter<any>();
  @Output() DeleteRow = new EventEmitter<any>();
  @Output() inputValue = new EventEmitter<any>();

  selectedAgency: any  = "";
  AgencyIdSelected= "";
  hasErrorAgency = false;
  stock= "";
  lang;
  hasErrorAgency2= false;

  constructor(
    private modalCtrl: ModalController,
    private sessiondata: SessiondataService,
  ) { }

  ngOnInit() {
    this.selectedAgency = this.agency || '';
    this.stock = this.myStock || '';
    this.lang = this.sessiondata.getJsonLanguages(this.sessiondata.currentlanguage);
    this.AgencyIdSelected = this.IDAgency || '';
  }

  deleteRow(){
    this.DeleteRow.emit(this.id);
  }


  async selectAgency(){
    const modal = await this.modalCtrl.create({
      component: AgenciesPage,
      cssClass: 'normal-modal',
      componentProps: {
        'from': 1,
        'choosen':  this.selectedAgency
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if(data){
      const agency: any = data.agency;
      const AgencyId: any = data.id;
      if(!!agency ){
        this.selectedAgency = agency;
        this.AgencyIdSelected = AgencyId;
        this.hasErrorAgency= false;
        debugger;
        const pos = this.currentArray.findIndex(age => age.IDAgency == AgencyId)
        if(pos >= 0){
          this.hasErrorAgency= true;
          this.AgencyIdSelected = '';
          this.OnClick.emit({IDAgency: '', id: this.id});
        }else{
          this.OnClick.emit({IDAgency: AgencyId, id: this.id});
        }
      }
      else
        this.hasErrorAgency= this.selectedAgency === "";
    }else{
      this.hasErrorAgency=  this.selectedAgency === "";
    }
  }


  numberOnlyValidation(event: any) {
    const { target } = event;
    const val = target.value === "" ? 0 : parseInt(target.value)
    this.inputValue.emit({value: val, id: this.id } )
  }
}
