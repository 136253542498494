import { Exonet } from "../client/client";
import { Output } from "../register/register";
import { Activity } from "../user/id";

export const ProviderTypeList = {
    PERSON: 'PERSON',
    COMPANY: 'COMPANY',
};

export interface ClientAddressInput {
    code: string;
    name: string;
}

export interface AddressTypeInput {
    province:ClientAddressInput,
    canton:ClientAddressInput,
    district:ClientAddressInput,
    neighborhood:ClientAddressInput,
    exactAddress: string
}

export enum ProviderTypeEnum {
    PERSON,
    COMPANY,
};

export enum SocietyTypeEnum {
    SA_SRL,
    JuntaEducacion,
    Fundacion,
    Municipalidad,
    NoJuridica
};

export enum IdentificationTypeEnum {
    NACIONAL,
    DIMEX,
    NITE
};

export interface AddProviderOutput extends Output {
    code: Number;
    data: Provider;
};

export interface DeleteProvidertOutput extends Output {
    code: Number;
}

export interface GetUserProvidersOutput extends Output {
    code: Number
    data: Array<Provider>
}

export interface DeleteProviderInput {
    providerID: string;
}

export interface InputActivityType {
    state: string;
    code: string;
    description: string;
    type: string;
}
export interface AddProviderInput {
    providerType: string;
    landLinePhone: PhoneNumberInput
    movilePhone: PhoneNumberInput;
    email: string;
    identification: string;
    address: AddressTypeInput;
    identificationType?: string;
    name?: string
    lastName?: string
    businessName?: string
    tradeName?: string
    societyType?: SocietyTypeEnum;
    profilePicture: string;
    activities: InputActivityType[];
}

export interface Provider {
    _id?: any;
    IDUser?: any;
    landLinePhone: PhoneNumberInput;
    movilePhone: PhoneNumberInput;
    email: string;
    providerType: string;
    address: AddressTypeInput;
    clientType: any;
    name: string;
    lastName: string;
    identificationType: string;
    identification: string;
    businessName: string;
    tradeName: string;
    societyType: SocietyTypeEnum;
    profilePicture: string;
    activities: InputActivityType[];
    exonet?: Array<Exonet>;
}

export interface PhoneNumberInput {
    e164: string;
    nationalNumber: string;
    countryCode: string;
}

