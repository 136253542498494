import { Injectable } from '@angular/core';
import { SessiondataService } from '../sessiondata/sessiondata.service';

@Injectable({
  providedIn: 'root'
})
export class LoginAuthService {

  constructor(private session : SessiondataService) { }

  async isLoggedIn() : Promise<boolean>{
    return new Promise(async (resolve)=>{
      try{
        const isLoggedIn : any = await this.session.getData('isLoggedIn');
        resolve(isLoggedIn.saved)
      }catch(e){
        resolve(false);
      }
    })
  }
}
