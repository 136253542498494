import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { AlertService } from 'src/providers/alert/alert.service';
import { AndroidPermissionsService } from 'src/providers/android-permissions/android-permissions.service';
import { ChoosePicService } from 'src/providers/choose-pic/choose-pic.service';
import { LoadingService } from 'src/providers/loading/loading.service';
import { SessiondataService } from 'src/providers/sessiondata/sessiondata.service';
import { UnitPagePage } from '../unit-page/unit-page.page';
import { currencies } from 'src/constants/currency';
import { CabysCodePage } from '../cabys-code/cabys-code.page';
import { AgencyRowComponent } from 'src/components/agency-row/agency-row.component';
import { generate } from 'shortid';
import { GetMeasureUnitsOutput, MeasureUnit } from 'src/constants/connections/measureUnits/measureUnits';
import { GetTaxRatesOutput, TaxRate } from 'src/constants/connections/taxRate/taxrates';
import { GraphqlConnectionService } from 'src/providers/graphql-connection/graphql-connection.service';
import { TimeProviderService } from 'src/providers/time-provider/time-provider.service';
import { getTaxRates } from 'src/constants/connections/taxRate/queries';
import { PlacesService } from 'src/providers/places/places.service';
import { AddProductDataOutput, DeleteProductDataOutput, Product, ProductAgency } from 'src/constants/connections/product/product';
import { addProductData, deleteProductByCode, getImportantValues, updateProductData } from 'src/constants/connections/product/queries';
import { Agency, GetUserAgenciesOutput } from 'src/constants/connections/agency/agency';
import { getAgencies } from 'src/constants/connections/agency/queries';
import { getMeasureUnitsService, getServiceUnitsTypes } from 'src/constants/connections/measureUnits/queries';
import { AndroidBackButtomService } from 'src/providers/navigation/android-back-button';

@Component({
  selector: 'app-new-product',
  templateUrl: './new-product.page.html',
  styleUrls: ['./new-product.page.scss'],
})
export class NewProductPage implements OnInit {

  clickedImage: any ="";
  lang: any;
  buttons = [ ];

  formProdcut;
  insuAgro;
  @Input() from: boolean;
  @Input() product: Product;
  @Input() numberProduct: number;

  selectedCurrency: any = "";
  selectedWarehouse: any = "";
  selectedAgency: any= "";
  selectedCabys: any= "";
  selectedUnit: MeasureUnit;
  selectedTaxRate: any = '';
  fullTaxRate:TaxRate;
  arrayOfAgencies: Array<ProductAgency> = [];
  arrayOfAgenciesSubs: Array<ProductAgency> = [];
  //ERRORS
  hasErrorCurrency;
  hasErrorWarehouse;
  hasErrorAgency;
  hasErrorCabys;
  hasErrorUnit;
  hasErrorTaxRates;
  //form_contols 
  fullCurrency: any;
  product_type: FormControl;
  product_currency: FormControl;
  product_unitPrice: FormControl;
  product_name: FormControl;
  product_description: FormControl;
  product_unit: FormControl;
  product_unitDetail: FormControl;
  product_insuAgro: FormControl;
  product_cabys:  FormControl;
  product_share:  FormControl;
  product_taxRates:  FormControl;

  component = AgencyRowComponent;
  isLoading;
  taxRatesList = []
  rates: Array<TaxRate>= [];
  measures: Array<MeasureUnit> = []
  agencies: Array<Agency> = [];
  service = "";
  @ViewChild('main', { read: ViewContainerRef }) entry: ViewContainerRef;

  curreUnit = {
    description: "Unidad",
    symbol: "Unid"
  }

  constructor(
    public resolver: ComponentFactoryResolver,
    private choosePic : ChoosePicService,
    private sessiondata: SessiondataService,
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    private permission: AndroidPermissionsService,
    private loadingController: LoadingService,
    private alertService: AlertService,
    private timeValidate: TimeProviderService,
    private connection: GraphqlConnectionService,
    private places: PlacesService,
    private backButton: AndroidBackButtomService) { }

  async ngOnInit() {
    this.lang = this.sessiondata.getJsonLanguages(this.sessiondata.currentlanguage);
    this.service = this.lang.NEW_PRODUCT.SERVICE.value;
    this.insuAgro =  { ...this.lang.NEW_PRODUCT.INSU_AGRO};
    this.productForm();
    this.getTaxRate();
    this.buttons = [
      {
        text: this.lang.BUTTONS.BTNCANCEL,
        role: 'cancel',
        cssClass: 'btnModalDismiss'
      }, {
        text: this.lang.BUTTONS.BTNACCEPT,
        cssClass: 'btnModalAcept'
      }
    ];
  }

  ionViewDidEnter() { 
    this.registerActionButton(); 
  }

  ionViewWillLeave() { 
    this.backButton.deregisterAction(); 
  }

  registerActionButton(){
    this.backButton.registerAction(()=>{
      this.backButton.goBack().then(
        () => this.redirectPage(),
        error => console.log(error)
      );
    }, 100);
  }

  async choosePicture(){
    try{
      const wantPic = await this.choosePic.chooseTypeAndTakePhoto(this.lang);
      if(wantPic){
        try{
          const permission = await this.permission.hasPermissions(this.permission.permission2s());
          if(permission){
            const image = await this.choosePic.takephoto(wantPic);
            if(image){
              this.clickedImage = image;
            }
          }else{
            const askPermission = await this.permission.requestPermissions(this.permission.permission2s());
            if(askPermission){
              const image = await this.choosePic.takephoto(wantPic);
              if(image){
                this.clickedImage = image;
              }
            }else{
              console.log('error')
            }
          }
        }catch(e){
          console.log(e)
        }
      }
    }catch(e){
      console.log(e);
    }
  }

  async productForm(){
    const {
      isService,
      currency,
      price,
      name,
      description,
      measureUnit,
      measureDetail,
      agriculturalInput,
      CABYScode,
      shareUrl,
      imageUrl,
      stock,
      taxRate
    } = this.product || {};
    
    this.arrayOfAgenciesSubs = stock;
    const { NEW_PRODUCT } =  this.lang
    const { SERVICE, PRODUCT } = NEW_PRODUCT;
    const typeOf = isService ? SERVICE.value : PRODUCT.value;
    
    this.product_type = new FormControl(typeOf || '', [
      Validators.required
    ]);

    let currentCurrLabel = ''

    if(!this.from){
      const currentCurr = currencies.find(curr => curr.code == currency);
      this.fullCurrency = currentCurr;
      currentCurrLabel = currentCurr? currentCurr.label : '';
    }

    // We need to give a hint of the object type to the control
    let measureUnitObj = {
      description: '',
      symbol: measureUnit || ''
    }
    
    this.product_currency = new FormControl(currentCurrLabel, [Validators.required]);
    this.product_unitPrice = new FormControl(price || '', [Validators.required]);
    this.product_name = new FormControl(name || '', [Validators.required]);
    this.product_description = new FormControl(description || '', [Validators.required]);
    this.product_unit = new FormControl({...measureUnitObj}, [Validators.required]);
    this.product_unitDetail = new FormControl(measureDetail || '');
    this.product_insuAgro = new FormControl(agriculturalInput || false, [Validators.required]);
    this.product_cabys = new FormControl(CABYScode || '', [Validators.required]);
    this.product_share = new FormControl(shareUrl || '');

    this.product_taxRates = new FormControl(taxRate || '', [Validators.required]);

    this.clickedImage = imageUrl || '';
    this.insuAgro.checked = agriculturalInput || false;

    this.formProdcut = this.formBuilder.group({
      product_type: this.product_type,
      product_currency: this.product_currency,
      product_unitPrice: this.product_unitPrice,
      product_name: this.product_name,
      product_description: this.product_description,
      product_unit: this.product_unit,
      product_unitDetail: this.product_unitDetail,
      product_insuAgro: this.product_insuAgro,
      product_cabys: this.product_cabys,
      product_share: this.product_share,
      product_taxRates: this.product_taxRates
    });

   
      if(!this.from){
        try{
          const values = await this.getValuesFromLocal();
          if(!!values[0]){
            this.rates = values[0];
            const currRate = this.rates.find(tax=> tax.code == taxRate || tax.code == taxRate );
            this.fullTaxRate = currRate;
            this.selectedTaxRate = currRate;
            this.formProdcut.controls["product_taxRates"].setValue(this.selectedTaxRate.name);
          }else{
            this.getTaxRateBackend();
          }
  
          if(!!values[1]){
            this.measures = values[1];
            const currRate = this.measures.find(measureInt=> measureInt.symbol == measureUnit);
            this.selectedUnit = currRate;
            this.product_unit.setValue(this.selectedUnit);
            this.formProdcut.controls["product_unit"].setValue(this.selectedUnit);
            this.hasErrorUnit= false;
          }else{
            this.getMeasureUnitsBackend();
          }
  
          if(!!values[2]){
            this.agencies = values[2];
            if(!!stock && stock.length > 0){
              this.arrayOfAgencies = [...stock.map(current => { return {...current, id: generate()} })];
              setTimeout(()=> this.agenciesFromEdit(), 200);
            }
          }else{
            this.getBackendAgencies();
          }
        }catch(e){
          await this.getValuesFromBack();
        }
      }
  }

  setChecked($event,checkedObj,type){
    checkedObj.checked = $event;
    this.formProdcut.controls[type].setValue($event);
  }

  getType(type){
    return this.formProdcut.controls[type].value;
  }

  setType(type,value){
    this.formProdcut.controls[type].setValue(value);
  }

  getMessageError(value,type){
    const forms =  this.lang.FORMS;

    if(!!value.errors){
      const { required, pattern } = value.errors
      if(required){
        return forms.REQUIRED;
      }else if(!!pattern){

        if(type == 'number'){
          return forms.NUMBER;
        }

        return forms.REQUIRED;
      }
    }
  }

  async selectCurrency(){
    try{
      const response: any = await this.alertService.showListAlert(this.lang.NEW_PRODUCT.SELECT_CURRENCY, this.buttons,'customAlert', currencies ,this.selectedCurrency);

      if(response){
        this.fullCurrency = currencies.find(curr => curr.label == response);
        this.selectedCurrency = response;
        this.hasErrorCurrency = false;
        this.formProdcut.controls["product_currency"].setValue(this.selectedCurrency);
      }else{
        this.hasErrorCurrency = this.selectedCurrency ==="" && this.getType('product_currency') === '';
      }
    }catch(e){
      this.hasErrorCurrency = this.selectedCurrency ==="" && this.getType('product_currency') === '';
    }
  }

  async selectUnit(){
    console.log("selectedUnit   "+JSON.stringify(this.selectedUnit)+"  "+JSON.stringify(this.product_type.value))
    const modal = await this.modalCtrl.create({
      component: UnitPagePage,
      cssClass: 'normal-modal',
      componentProps: {
        'choosen': this.selectedUnit,
        'isService': this.product_type.value == 'servicio' ? true : false 
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if(data){
      const unit: MeasureUnit = data.unitFull;
      if(!!unit ){
        this.selectedUnit = unit;
        this.formProdcut.controls["product_unit"].setValue(this.selectedUnit);
        this.hasErrorUnit= false;
      }
      else
        this.hasErrorUnit = !!this.selectedUnit;
    }else{
      this.hasErrorUnit = !!this.selectedUnit;
    }
  }

  async selectCabys(){
    const modal = await this.modalCtrl.create({
      component: CabysCodePage,
      cssClass: 'normal-modal',
      componentProps: {
        'from': 1,
        'choosen': this.selectedCabys
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if(data){
      const cabys: string = data.cabys;
      if(!!cabys ){
        this.selectedCabys = cabys;
        this.formProdcut.controls["product_cabys"].setValue(this.selectedCabys);
        this.hasErrorCabys= false;
      }
      else
        this.hasErrorCabys= this.selectedCabys === "";
    }else{
      this.hasErrorCabys=  this.selectedCabys === "";
    }
  }

  getCurrentProduct(){
    const { controls } = this.formProdcut;
    const { NEW_PRODUCT } =  this.lang
    const { SERVICE, PRODUCT } = NEW_PRODUCT;
    if(!!controls){
      const product : Product = {
        isService:  controls['product_type'].value === SERVICE.value ,
        isProduct:  controls['product_type'].value === PRODUCT.value,
        currency:  this.fullCurrency.code,
        price:  controls['product_unitPrice'].value,
        name: controls['product_name'].value,
        description:  controls['product_description'].value,
        measureUnit: this.selectedUnit.symbol,
        measureDetail: (controls['product_unitDetail'].value) ? controls['product_unitDetail'].value : 1,
        agriculturalInput:  controls['product_insuAgro'].value,
        CABYScode:  controls['product_cabys'].value,
        shareUrl:  controls['product_share'].value,
        taxRate: this.fullTaxRate.code,
        imageUrl: this.clickedImage,
        stock: this.arrayOfAgencies.map(agency => { return  {...agency,  stock : !agency.stock ? 1 :  agency.stock  }})
      }

      return this.from ? product : {
        product: product,
        idProduct: this.product._id
      };

    }
    return null;
  }

  async createUpdatProduct(){
    if(!this.selectedUnit){
      this.hasErrorUnit = true;
      return;
    }
    this.isLoading = true;
    const newProdcut: any = this.getCurrentProduct();
    const { ERRORS } = this.lang;
    try {
      const mutation = this.from ? addProductData(newProdcut): updateProductData(newProdcut);
      const response = await this.connection.post(mutation, true);
      this.isLoading = false;
      if(response && response.data){
        const { addProductData, updateProductData } : any = response.data;
        const { success, data, message } : AddProductDataOutput = this.from ? addProductData: updateProductData;
        if(success && data){
          this.alertService.presentToast(message, 'primary');
          const savedProduct : Product = data;
          this.redirectPage(savedProduct,  this.from ? 'add' : 'edit');
        }else{
          this.alertService.presentToast(message);
        }
      }else{
        this.alertService.presentToast( this.from ? ERRORS.CREATINGPRODUCT: ERRORS.UPDATEPRODUCT);
      }
    }catch(e){
      this.alertService.presentToast( this.from ? ERRORS.CREATINPRODUCT: ERRORS.UPDATEPRODUCT);
      this.isLoading = false;
    }
  }

  async deleteProduct(){
    this.isLoading = true;
    const { ERRORS } = this.lang;
    try{
      const mutation = deleteProductByCode(this.product._id)
      const response = await this.connection.post(mutation, true);
      this.isLoading = false;
      if(response && response.data){
        const { deleteProduct } : any = response.data;
        const { success, message } : DeleteProductDataOutput = deleteProduct;
        if(success){
          this.alertService.presentToast(message, 'primary');
          this.redirectPage(this.product, 'delete');
        }else{
          this.alertService.presentToast(message);
        }
      }else{
        this.alertService.presentToast(ERRORS.DELETEPRODUCT);
      }
    }catch(e){
      this.alertService.presentToast(ERRORS.DELETEPRODUCT);
    }

  }

  savedOndraftClears(value, newProductData, type){
      let message = '';
      this.loadingController.loadingDismiss();
      const newProduct = this.getCurrentProduct();
      if(value){
        this.resetForm();
        switch(type){
          case 'add': message = newProductData.SAVING_PRODUCT_SUCCESS; break;
          case 'edit': message = newProductData.EDIT_PRODUCT_SUCCESS; break;
          case 'delete': message = newProductData.DELETE_PRODUCT_SUCCESS; break;
        }
        this.alertService.presentToast(message,'primary');
        this.redirectPage(newProduct,type)
      }else{
        switch(type){
          case 'add': message = newProductData.SAVING_PRODUCT_ERROR; break;
          case 'edit': message = newProductData.EDIT_PRODUCT_ERROR; break;
          case 'delete': message = newProductData.DELETE_PRODUCT_ERROR; break;
        }
        this.alertService.presentToast(message);
      }
  }


  resetForm(){
    this.formProdcut.reset();

    this.selectedCurrency= "";
    this.selectedWarehouse=  "";
    this.selectedAgency= "";
    this.selectedCabys= "";
    this.selectedUnit= null;

    //ERRORS
    this.hasErrorCurrency;
    this.hasErrorWarehouse;
    this.hasErrorAgency;
    this.hasErrorCabys;
    this.hasErrorUnit;

    this.insuAgro =  { ...this.lang.NEW_PRODUCT.INSU_AGRO};
  }


  redirectPage(product = null, action = ""){
    this.modalCtrl.dismiss({
      product,
      action
    })
  }

  agenciesFromEdit(){
    this.arrayOfAgencies.forEach(currAgency=>{
      const {IDAgency, id, stock} = currAgency;
      const arrAgency = this.agencies.find(suppAgency => suppAgency._id === IDAgency )
      this.singleAgency(id,IDAgency,stock, arrAgency?.agencyName);
    });
  }

  singleAgency(id, IDAgency, stockNumber, arrAgency?){
      const factory = this.resolver.resolveComponentFactory(this.component as any);
      const ref : any = this.entry.createComponent(factory);
      ref.instance.id = id || generate();
      ref.instance.IDAgency = IDAgency || '';
      ref.instance.myStock = stockNumber || '';
      ref.instance.currentArray = this.arrayOfAgencies;
      if(arrAgency)
        ref.instance.agency = arrAgency || '';

      ref.instance.OnClick.subscribe(({IDAgency, id}) => {
        const pos = this.arrayOfAgencies.findIndex( agencyCurrent => id == agencyCurrent.id );       
        if(pos > -1){
          this.arrayOfAgencies[pos] = {
            ...this.arrayOfAgencies[pos],
            IDAgency: IDAgency
          }

          ref.instance.currentArray = this.arrayOfAgencies;
        }
      });
  
      ref.instance.inputValue.subscribe(({value,id}) => {
        const pos = this.arrayOfAgencies.findIndex( agencyCurrent => id == agencyCurrent.id ); 
        if(pos > -1){
          this.arrayOfAgencies[pos] = {
            ...this.arrayOfAgencies[pos],
            stock: value
          }
          ref.instance.currentArray = this.arrayOfAgencies;
        }
      });
  
      ref.instance.DeleteRow.subscribe(id => {
        const pos = this.arrayOfAgencies.findIndex( agencyCurrent => { return  id == agencyCurrent.id } ); 
        if(pos > -1){
          this.entry.remove(pos);
          this.arrayOfAgencies.splice(pos, 1);
          ref.instance.currentArray = this.arrayOfAgencies;
        }
      });
  }

  moreAgencies(){

    this.arrayOfAgencies.push({
      IDAgency: '',
      id: generate(),
      stock: 0
    })

    const current = this.arrayOfAgencies[this.arrayOfAgencies.length - 1];
    const {IDAgency, id,stock} = current;
    
    this.singleAgency(id,IDAgency,stock);

  }


  isArrayOfAgenciesValid(){
    if(this.arrayOfAgencies.length > 0){
      const isService = this.product_type && this.product_type.value === "servicio";
      const errorPlace = this.arrayOfAgencies.find( agencyCurrent => agencyCurrent.IDAgency === ''); 
     // const errorNumber = !isService ? this.arrayOfAgencies.find( agencyCurrent => agencyCurrent.stock === 0 ) : false;  TODO ACTIVAR CUANDO LOS CAMPOS SEAN OBLIGATORIOS
      return errorPlace; //|| errorNumber;
    }
    return false;
  }

  async selectTaxRates(){
    try{
      const response: any = await this.alertService.showListAlert(this.lang.NEW_PRODUCT.SELECT_TAX, this.buttons,'customAlert', this.places.createRadioPlaces(this.taxRatesList,'name','code','') ,this.selectedTaxRate);
      if(response){
        this.fullTaxRate = this.taxRatesList.find(tax => tax.name === response);
        this.selectedTaxRate = response;
        this.hasErrorTaxRates = false;
        this.formProdcut.controls["product_taxRates"].setValue(this.selectedTaxRate);
      }else{
        this.hasErrorTaxRates = this.selectedTaxRate === '' && this.getType('product_taxRates') === '';
      }
    }catch(e){
      this.hasErrorTaxRates = this.selectedTaxRate === '' && this.getType('product_taxRates') === '';
    }
  }

  async getTaxRate(){
    try{
      const time : any = await this.sessiondata.getData('lastTimetaxRatesList');
      if(time){
        const validated = this.timeValidate.isLessThanXMins(new Date(), new Date(time), 240);
        validated ? this.getTaxRateBackend() : this.getLocalTaxRate();
      }else{
        this.getTaxRateBackend();
      }
    }catch(e){
      this.getTaxRateBackend();
    }
  }


  async getTaxRateBackend(){
      const measures = getTaxRates();
      try{
        const response = await this.connection.post(measures);
        if(response.data){
          const { getTaxRates } : any = response.data;
          const { success, data, message } : GetTaxRatesOutput  = getTaxRates;
          if( success && data){
            const list : Array<TaxRate> = [...data];
            this.taxRatesList = [...list];
            this.rates = [...list];
            if(!this.from){
              const currRate = this.rates.find(tax=> tax.name == this.getType('product_taxRates') || tax.code == this.getType('product_taxRates'));
              this.fullTaxRate = currRate;
              this.selectedTaxRate = currRate;
              this.formProdcut.controls["product_taxRates"].setValue(this.selectedTaxRate.name);
            }else{
              this.sessiondata.setData('taxRatesList',[...this.taxRatesList]);
              this.sessiondata.setData('lastTimetaxRatesList', Date.now());
            }
          }else{
            this.alertService.presentToast(message);
          }
        }else{
          this.alertService.presentToast(this.lang.ERRORS.TAXRATES); 
        }
      }catch(e){
        this.alertService.presentToast(this.lang.ERRORS.TAXRATES); 
      }
  }


  async getLocalTaxRate(){
    try{
      const list: any = await this.sessiondata.getData('taxRatesList');
      if(list){
        this.taxRatesList = [...list];
      }else{
        this.taxRatesList = [];
      }
    }catch(e){
      this.taxRatesList = [];
    }
  }

  async getValuesFromLocal(){
    const arrayOfPromises = [this.sessiondata.getData('taxRatesList'), this.sessiondata.getData('measureUnits'), this.sessiondata.getData('agencylist')];
    return Promise.all(arrayOfPromises).then(values => {
      return values
    }).catch(error => {
      return error
    });
  }

  async getValuesFromBack(){
    const values = getImportantValues();
      try{
        const response = await this.connection.post(values,true);
        if(response.data){
          const { getTaxRates, getMeasureUnits , getUserAgencies } : any = response.data;
          const { success: SuccesOne, data : dataRate,  } : GetTaxRatesOutput  = getTaxRates;
          const { success : SuccesTwo, data: dataMeasure,  } : GetMeasureUnitsOutput  = getMeasureUnits;
          const { success : SuccesThree, data: dataAgencies } : GetUserAgenciesOutput  = getUserAgencies;
          

          if(SuccesOne && dataRate){
              this.rates = dataRate;
              const currRate = this.rates.find(tax=> tax.code == this.getType('product_taxRates'));
              this.fullTaxRate = currRate;
              this.selectedTaxRate = currRate;
              this.formProdcut.controls["product_taxRates"].setValue(this.selectedTaxRate.name);
          }

          if(SuccesTwo && dataMeasure){
              this.measures = dataMeasure;
              const currRate = this.measures.find(measureInt=> measureInt.symbol == this.getType('product_unit'));
              this.selectedUnit = currRate;
              this.formProdcut.controls["product_unit"].setValue(this.selectedUnit);
          }


          if(SuccesThree && dataAgencies){
            this.agencies = dataAgencies;
            if(!!this.arrayOfAgenciesSubs && this.arrayOfAgenciesSubs.length > 0){
              this.arrayOfAgencies = [...this.arrayOfAgenciesSubs.map(current => { return {...current, id: generate()} })];
              setTimeout(()=> this.agenciesFromEdit(), 200);
            }
          }

        }else{
          this.alertService.presentToast(this.lang.ERRORS.TAXRATES); 
        }
      }catch(e){
        this.alertService.presentToast(this.lang.ERRORS.TAXRATES); 
      }
  }

  async getBackendAgencies(){
    const query = getAgencies();
    try{
      const response = await this.connection.post(query, true);
      if(response.data){
        const { getUserAgencies } : any = response.data;
        const { success, data, message } : GetUserAgenciesOutput = getUserAgencies;
        if( success && data ){
          const list: Array<Agency> = data;
          this.agencies = list;
          if(!!this.arrayOfAgenciesSubs && this.arrayOfAgenciesSubs.length > 0){
            this.arrayOfAgencies = [...this.arrayOfAgenciesSubs.map(current => { return {...current, id: generate()} })];
            setTimeout(()=> this.agenciesFromEdit(), 200);
          }
        } 
      }else{
        this.alertService.presentToast(this.lang.AGENCY.ERRORBACKENDGET); //TODO response.errors
      } 
    }catch(e){
      this.isLoading = false;
      this.alertService.presentToast(this.lang.AGENCY.ERRORBACKENDGET);
    }
  }

  async getMeasureUnitsBackend(){
    const measures = this.product_type.value=="producto" ? getMeasureUnitsService():getServiceUnitsTypes();
    try{
      const response = await this.connection.post(measures);
      this.isLoading = false;
      if(response.data){
        const measureUnits : any = response.data;
        if(this.product_type.value=="producto" )
          var { success, data, message } : GetMeasureUnitsOutput  = measureUnits.getProductUnits;
        else
          var { success, data, message } : GetMeasureUnitsOutput  = measureUnits.getServiceTypes;
        if( success && data){
          const list : Array<MeasureUnit> = [...data];
          const currRate = list.find(measureInt=> measureInt.symbol == this.getType('product_unit').symbol);
          this.selectedUnit = currRate;
          this.formProdcut.controls["product_unit"].setValue(this.selectedUnit);
        }else{
          this.alertService.presentToast(message);
        }
      }else{
        this.alertService.presentToast(this.lang.ERRORS.MEASURE); 
      }
    }catch(e){
      this.isLoading = false;
      this.alertService.presentToast(this.lang.ERRORS.MEASURE); 
    }
}

}
