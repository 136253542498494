import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AgenciesPageRoutingModule } from './agencies-routing.module';

import { AgenciesPage } from './agencies.page';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'src/components/components.module';
import { NewAgencyPageModule } from '../new-agency/new-agency.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AgenciesPageRoutingModule,
    ComponentsModule,
    TranslateModule,
    NewAgencyPageModule
  ],
  declarations: [AgenciesPage]
})
export class AgenciesPageModule {}
