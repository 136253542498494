import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AgenciesPageModule } from 'src/pages/agencies/agencies.module';
import { ClientsPageModule } from 'src/pages/clients/clients.module';
import { ClientsPage } from 'src/pages/clients/clients.page';
import { NewProductPageModule } from 'src/pages/new-product/new-product.module';
import { ProductsPageModule } from 'src/pages/products/products.module';
import { ProvidersPageModule } from 'src/pages/providers/providers.module';
import { AuthGuardService } from 'src/providers/authGuard/auth-guard.service';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home-dash-board',
    pathMatch: 'full'
  },
  {
    path: 'onboardscreen',
    loadChildren: () => import('src/pages/onboardscreen/onboardscreen.module').then( m => m.OnboardscreenPageModule)
  },
  {
    path: 'phonenumber',
    loadChildren: () => import('src/pages/phonenumber/phonenumber.module').then( m => m.PhonenumberPageModule)
  },
  {
    path: 'verifynumber',
    loadChildren: () => import('src/pages/verifynumber/verifynumber.module').then( m => m.VerifynumberPageModule)
  },
  {
    path: 'socialprofiles',
    loadChildren: () => import('src/pages/socialprofiles/socialprofiles.module').then( m => m.SocialprofilesPageModule),
    
  },
  {
    path: 'signup',
    loadChildren: () => import('src/pages/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'home-dash-board',
    loadChildren: () => import('src/pages/home-dash-board/home-dash-board.module').then( m => m.HomeDashBoardPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'notification',
    loadChildren: () => import('src/pages/notification/notification.module').then( m => m.NotificationPageModule)
  },
  {
    path: 'invoice',
    loadChildren: () => import('src/pages/facturacion/facturacion.module').then( m => m.FacturacionPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('src/pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'products-services',
    loadChildren: () => import('src/pages/products-services/products-services.module').then( m => m.ProductsServicesPageModule)
  },
  {
    path: 'clients',
    loadChildren: () => import('src/pages/clients/clients.module').then( m => m.ClientsPageModule)
  },
  {
    path: 'reception',
    loadChildren: () => import('src/pages/reception/reception.module').then( m => m.ReceptionPageModule)
  },
  {
    path: 'soon',
    loadChildren: () => import('src/pages/soon/soon.module').then( m => m.SoonPageModule)
  },
  {
    path: 'client-profile',
    loadChildren: () => import('src/pages/client-profile/client-profile.module').then( m => m.ClientProfilePageModule)
  },
  {
    path: 'new-client',
    loadChildren: () => import('src/pages/new-client/new-client.module').then( m => m.NewClientPageModule)
  },
  {
    path: 'new-product',
    loadChildren: () => import('src/pages/new-product/new-product.module').then( m => m.NewProductPageModule)
  },
  {
    path: 'products',
    loadChildren: () => import('src/pages/products/products.module').then( m => m.ProductsPageModule)
  },
  {
    path: 'unit-page',
    loadChildren: () => import('src/pages/unit-page/unit-page.module').then( m => m.UnitPagePageModule)
  },
  {
    path: 'agencies',
    loadChildren: () => import('src/pages/agencies/agencies.module').then( m => m.AgenciesPageModule)
  },
  {
    path: 'cabys-code',
    loadChildren: () => import('src/pages/cabys-code/cabys-code.module').then( m => m.CabysCodePageModule)
  },
  {
    path: 'register-user',
    loadChildren: () => import('src/pages/register-user/register-user.module').then( m => m.RegisterUserPageModule)
  },
  {
    path: 'terms-conditions',
    loadChildren: () => import('src/pages/terms-conditions/terms-conditions.module').then( m => m.TermsConditionsPageModule)
  },
  {
    path: 'new-agency',
    loadChildren: () => import('src/pages/new-agency/new-agency.module').then( m => m.NewAgencyPageModule)
  },
  {
    path: 'configuration',
    loadChildren: () => import('src/pages/configuration/configuration.module').then( m => m.ConfigurationPageModule)
  },
  {
    path: 'inbox',
    loadChildren: () => import('src/pages/inbox/inbox.module').then( m => m.InboxPageModule)
  },
  {
    path: 'resume-presentation',
    loadChildren: () => import('src/pages/resume-presentation/resume-presentation.module').then( m => m.ResumePresentationPageModule)
  },
  {
    path: 'debit-credit-note',
    loadChildren: () => import('src/pages/debit-credit-note/debit-credit-note.module').then( m => m.DebitCreditNotePageModule)
  },
  {
    path: 'emit-dc-note',
    loadChildren: () => import('src/pages/emit-dc-note/emit-dc-note.module').then( m => m.EmitDcNotePageModule)
  },
  {
    path: 'account-configuration',
    loadChildren: () => import('src/pages/account-configuration/account-configuration.module').then( m => m.AccountConfigurationPageModule)
  },
  {
    path: 'password-recovery',
    loadChildren: () => import('src/pages/password-recovery/password-recovery.module').then( m => m.PasswordRecoveryPageModule)
  },
  {
    path: 'email-code',
    loadChildren: () => import('src/pages/email-code/email-code.module').then( m => m.EmailCodePageModule)
  },
  {
    path: 'new-password',
    loadChildren: () => import('src/pages/new-password/new-password.module').then( m => m.NewPasswordPageModule)
  },
  {
    path: 'language',
    loadChildren: () => import('src/pages/language/language.module').then( m => m.LanguagePageModule)
  },
  {
    path: 'providers',
    loadChildren: () => import('src/pages/providers/providers.module').then( m => m.ProvidersPageModule)
  },
  {
    path: 'new-provider',
    loadChildren: () => import('../pages/new-provider/new-provider.module').then( m => m.NewProviderPageModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('../pages/reports/reports.module').then( m => m.ReportsPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),ClientsPageModule, ProvidersPageModule, AgenciesPageModule,ProductsPageModule,NewProductPageModule
  ],
  //declarations: [ClientsPageModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
