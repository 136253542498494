import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit {


  @Input('show') show: boolean;
  @Input('fullName') fullName: string;
  @Input('date') date: any;
  @Input('textClass') textClass: string;
  @Output() showCollapse = new EventEmitter<any>();

  iconName = "caret-down-outline";

  constructor() { }

  ngOnInit() {
    this.iconName = this.show ? "caret-up-outline" : "caret-down-outline"
  }

  handleClick(){
    this.show = !this.show;
    this.iconName = this.show ? "caret-up-outline" : "caret-down-outline";
    this.showCollapse.emit(this.show);
  }

}
