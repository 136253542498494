import { Injectable } from '@angular/core';
import { SessiondataService } from '../sessiondata/sessiondata.service';




@Injectable({
  providedIn: 'root'
})
export class GraphqlConnectionService {

  //uri = 'https://apitest.facturamas.cr/facturamasQL';
  //uri = 'http://localhost:3150/facturamasQL';
  uri = 'https://www.api.production.facturamas.cr/facturamasQL';

  constructor(private sessiondata: SessiondataService) { 

  }

  async post(query, needsToken:boolean =  false){
    try {
      let headers: any = {'Content-Type': 'application/json'}
      console.log("needsToken "+needsToken)
      if(needsToken){
        const token = await this.sessiondata.getToken();
        //console.log("token "+token)
        if(token){
          headers = {...headers, 'Authorization': "Bearer "+token }
        }
      }
      console.log(query)
      const response = await fetch(this.uri, {
        headers:headers ,
        method: 'POST',
        body: JSON.stringify({ query }),
      });

      const responseJson = await response.json();
      //console.log("result "+JSON.stringify(responseJson))
      return responseJson;
    }catch(e){
      return null;
    }
  }


  async get(query){
    try {
      const response = await fetch(this.uri, {
        headers: {'Content-Type': 'application/json'},
        method: 'GET',
        body: JSON.stringify({ query }),
      });

      const responseJson = await response.json();
      return responseJson;
    }catch(e){
      return null;
    }
  }
}
