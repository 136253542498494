import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { patterns } from 'src/constants/pattern';
import { SessiondataService } from 'src/providers/sessiondata/sessiondata.service';

@Component({
  selector: 'app-exonet-line',
  templateUrl: './exonet-line.component.html',
  styleUrls: ['./exonet-line.component.scss'],
})
export class ExonetLineComponent implements OnInit {


  @Input() id: any;
  @Input() text: string;
  @Input() exonetArray: Array<any>;
  @Output() DeleteRow = new EventEmitter<any>();
  @Output() inputValue = new EventEmitter<any>();
  lang;

  exonet_value : FormControl;
  formExonet;
  constructor( private sessiondata: SessiondataService, private formBuilder: FormBuilder,) { }

  ngOnInit() {
    this.lang = this.sessiondata.getJsonLanguages(this.sessiondata.currentlanguage);
    this.setForm();
  }

  deleteRow(){
    this.DeleteRow.emit(this.id);
  }

  setForm(){
    this.exonet_value = new FormControl(this.text || '', [Validators.pattern(patterns.onlyExonet)]);
    this.formExonet = this.formBuilder.group({
      exonet_value : this.exonet_value
    })

    this.onChanges();
  }


  onChanges(){
    this.formExonet.get('exonet_value').valueChanges.subscribe(val => {
        this.inputValue.emit({value: val, id: this.id, isValid: val.match(patterns.onlyExonet) } )
    });
  }

  getError(value){
    if(!!value.errors){
      const newC = this.lang.NEW_CLIENT;
      const { pattern } = value.errors
      if(!!pattern){
        return newC.EXONETERR;
      }
    }
  }
}
