import { Injectable } from '@angular/core';
import { Platform, IonRouterOutlet } from '@ionic/angular';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { EventsService } from 'src/providers/events/events.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AndroidBackButtomService {
  private _deregisterFn = null;
  private active = false;
  constructor(
    private _platform: Platform,
    public router: Router, private keyboard: Keyboard,
    private events: EventsService) {
      this._platform.resume.subscribe(() => {
        console.log('ACTIVE APP FROM BACKGROUND');
        this.active = false;
      });
    }
  registerAction(myfunction, priority) {
    this._deregisterFn = this._platform.backButton.subscribeWithPriority(priority, async () => {
      myfunction();
    });
  }
  deregisterAction() { this._deregisterFn && this._deregisterFn.unsubscribe(); }

  goBack() { return new Promise(resolve => resolve("goback")); }

  doubleBackToExit() {
    if (!this.active) {
      this.active = true;
      navigator['app'].exitApp();
      return;
    }
  }

  goBackPageArrow(event?: boolean, eventName?: string, eventData?: any){
    !!this.keyboard.isVisible && this.keyboard.hide();
    event && this.events.publish(eventName, eventData);
  }
  
  goBackPage(page: string, params: any, ioRoute: IonRouterOutlet, event?: boolean, eventName?: string, eventData?: any){
    !!this.keyboard.isVisible && this.keyboard.hide();
    event && this.events.publish(eventName, eventData);
    (ioRoute.canGoBack()) ? ioRoute.pop() : this.router.navigate([page], {queryParams: params});
  }
  
  goBackLocation(location: Location, eventName?: string, eventData?: any){
    !!this.keyboard.isVisible && this.keyboard.hide();
    event && this.events.publish(eventName, eventData);
    location.back();
  }
}
