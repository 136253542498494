import { Injectable } from '@angular/core';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';


@Injectable({
  providedIn: 'root'
})
export class AndroidPermissionsService {

  constructor(private androidPermissions: AndroidPermissions) { 

  }

  hasPermissions(permissions): Promise<boolean>{
    return new Promise(async (resolve)=>{
      try{
        const result : any = await this.androidPermissions.checkPermission(permissions);
        if(result){
          resolve(result.hasPermission)
        }else{
          resolve(false)
        }
      }catch(e){
        console.log(e);
        resolve(false)
      }
    })
  }


  requestPermissions(permissions) : Promise<boolean> {
    return new Promise(async (resolve)=>{
      try{
        const result = await this.androidPermissions.requestPermission(permissions);
        if(result){
          resolve(result.hasPermission)
        }else{
          resolve(false)
        }
      }catch(e){
        console.log(e);
        resolve(false)
      }
    })
  }

  permission2s(){
    return this.androidPermissions.PERMISSION.CAMERA;
  }
}
