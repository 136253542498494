import { Component, Input, OnInit } from '@angular/core';
import { GraphqlConnectionService } from 'src/providers/graphql-connection/graphql-connection.service';
import { DocumentF, DocumentStatusEnum, UpdateUserMailBoxOutput } from 'src/constants/connections/inbox/inbox';
import { updateDocumentStatus } from 'src/constants/connections/inbox/queries';
import { AlertService } from 'src/providers/alert/alert.service';
import { SessiondataService } from 'src/providers/sessiondata/sessiondata.service';
import { PlacesService } from 'src/providers/places/places.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mail-box',
  templateUrl: './mail-box.component.html',
  styleUrls: ['./mail-box.component.scss'],
})

export class MailBoxComponent implements OnInit {

  
  lang;
  formNewInvoice;

  selectedActivity:any =  {description:'',code:''};
  selectedActSt: any= "";
  billingActivities: any = null;
  date: any= "";

  resumeLang;

  buttons = [];

  hasErrorAct:boolean = false;

  @Input('data') data: any;

  @Input('show') show: boolean;

  @Input('langData') langData: any;

  @Input('name') name: string;

  constructor(
    private router : Router,
    private connection: GraphqlConnectionService,
    private alertService: AlertService,
    private session: SessiondataService,
    private pService : PlacesService

  ) { 



  }

  ngOnInit() {
    this.lang = this.session.languageMessage;
    this.date = new Date(this.data.issuedDate*1000).toLocaleString();
    console.log("emission date "+this.data.emissionDate)
    this.buttons = [
      {
        text: this.lang.BUTTONS.BTNCANCEL,
        role: 'cancel',
        cssClass: 'btnModalDismiss'
      }, {
        text: this.lang.BUTTONS.BUTTONAPPLY,
        cssClass: 'btnModalAcept'
      }
    ];

    this.billingActivities = this.session.getBilling();

  }

  
async updateDocumentStatus(){
  const { ERRORS } = this.langData;
  try {
    //debugger;
    const mutation = updateDocumentStatus({spendingType:"",documentID:this.data._id,economicActivity:this.selectedActivity.code,documentStatus:DocumentStatusEnum.ACCEPTED});
    const response = await this.connection.post(mutation, true);
    if(response && response.data){
      const { updateDocumentStatus } : any = response.data;
      const { success, data, message } : UpdateUserMailBoxOutput  = updateDocumentStatus;
      if(success && data){
        this.alertService.presentToast(message, 'primary');
        const savedDocument : DocumentF = data;
      }else{
        this.alertService.presentToast(message);
      }
    }else{
      this.alertService.presentToast( response.updateDocumentStatus.message );
    }
  }catch(e){
    this.alertService.presentToast( e );
  }

}

getType(type){
  return this.formNewInvoice.controls[type].value;
}

redirectOnboard(){
  this.router.navigate(['home-dash-board']);
}


async chooseAct(){

  var billimg = this.session.getBilling();
  var activities = billimg.activities;

  try{
    const response: any = await this.alertService.showListAlert(this.lang.NEW_INVOICE.WHICH, this.buttons,'customAlert', this.pService.createRadioPlaces(activities,'description','code','') ,this.selectedActSt);

    if(response){
      this.selectedActSt = response;
      this.selectedActivity = activities.find((act)=> act.description == response)
      this.hasErrorAct = false;
      //this.formNewInvoice.controls["invoice_type_act"].setValue(this.selectedActSt);
    }else{
      //this.hasErrorAct = this.selectedActSt ==="" && this.getType('invoice_type_act') === '';
    }
  }catch(e){
    //this.hasErrorAct = this.selectedActSt ==="" && this.getType('invoice_type_act') === '';
  }
}

}
