import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { TranslateService } from '@ngx-translate/core'
import { SessiondataService } from '../providers/sessiondata/sessiondata.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public translate: TranslateService,
    public sessiondata: SessiondataService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if(this.platform.is('android')) {
        this.statusBar.styleBlackOpaque();
      }
      if(this.platform.is('ios')) {
        this.statusBar.overlaysWebView(true);
        this.statusBar.styleBlackOpaque();
      }
      this.setSessionData()
    });
  }


  setSessionData(){
    this.sessiondata.currentlanguage = 'es';
    this.translate.setDefaultLang('es');
    this.translate.use(this.sessiondata.currentlanguage)
  }
}
