import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { InvoiceCurrencyInput } from 'src/constants/connections/invoice/inputTypes/atvInvoiceCurrency';
import { AddInvoiceServiceDetailInput } from 'src/constants/connections/invoice/inputTypes/atvInvoiceServiceDetail';
import { Product } from 'src/constants/connections/product/product';
import { AlertService } from 'src/providers/alert/alert.service';

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ListItemComponent implements OnInit, OnChanges {

  @Input('data') data: AddInvoiceServiceDetailInput;
  @Input('colors') colors: string;
  @Output() InputEmmit = new EventEmitter<{code:string;data:any;}>();
  @Input('taxRatesList') taxRatesList: any;
  @Input('langData') langData: any;
  @Input('isStaticValue') isStaticValue: boolean;
  @Input('currency') currency: InvoiceCurrencyInput;
  @Input('placeholder') placeholder: string;

  totalPrice : number = 0;
  counter: number = 1;
  typeOfExp = "";

  constructor(private ref: ChangeDetectorRef, private alertService: AlertService) { }

  ngOnInit() {

    let { netTax, quantity, discount, lineTax, subTotal } =  this.data;
    
    console.log("product "+JSON.stringify(this.data));
    console.log("currency "+JSON.stringify(this.currency));
    /*
    const valTax = (!this.isStaticValue || !lineTax) ? netTax : lineTax[0].taxRate.code
    const posbileTax = this.taxRatesList.find(tax => parseInt(tax.code)  === parseInt(valTax))
    this.typeOfExp = posbileTax && posbileTax.name || '';
    this.counter = !this.isStaticValue ? quantity : '';

    if(discount && !discount[0].amount){
      discount.percetage = (discount && discount.amount) ? discount.amount / subTotal : 0;
    }else{
      discount = {
        percetage: 0,
        amount: 0
      }
      discount.percetage = (discount && discount.amount) ? discount.amount / subTotal : 0;
    }
    */
  }

  ngOnChanges(event){
    console.log("on changes")
    this.calculateTotal(this.data)
    this.ref.detectChanges();
  }

  ngDoCheck(){
    //const { unitPrice , discount } = this.data;
    //console.log("ngDoCheck "+JSON.stringify(this.data))
    //!this.isStaticValue ? this.calculateTotal(unitPrice, discount) : this.setValuetotal();
  }

  onInput(value){
    //console.log("value  "+JSON.stringify(value)+"statiValueeee  "+this.isStaticValue)
    console.log("value  onInput  isStatic"+JSON.stringify(value))
    const count = parseInt(value);
    this.InputEmmit.emit({code:"01",data:count});
    
    /*
    if(this.isStaticValue){
      console.log("value  onInput  isStatic"+JSON.stringify(value))
      this.InputEmmit.emit({code:"01",data:count}); 
    }
    /*
    if(this.isStaticValue){
      this.InputEmmit.emit(count);
    }else{
      const { unitPrice, discount } = this.data;
      try{
        
        //if(count > this.data.maxProd){
          this.counter = 1;
          this.alertService.presentToast(this.langData.ERROR3)
        }else{
          this.counter = isNaN(count) ? 1 : count;
          !this.isStaticValue && this.calculateTotal(price,discount.percentage);
          this.InputEmmit.emit(this.counter);
        }
        
      }catch(e){
        console.log(e);
      }
      
    }*/ 
  

    
  }

  calculateTotal(data,discount?){
    console.log("discount added")
    var prvPrice=0;
    var discountNeed=0;
    if(discount){
      discountNeed = (discount / 100);
    }
    prvPrice =  this.counter * data.price;
    this.totalPrice = prvPrice - (discountNeed * prvPrice);
    this.ref.detectChanges();
  }


  setValuetotal(){
    console.log("list Item setValueTotal")
    //this.totalPrice = this.data && this.data.subTotal || 0;
  }

}
