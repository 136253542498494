import { Canton, District, GetCantonsInput, GetDistrictsInput, GetNeighborhoodsInput, Neighborhood, Province } from "./places"

export const getProvinces = () =>{
    return `query {
        getProvinces {
            success
            message
            description
            data{
                id
                code
                province
            }
        }
    }`
}

export const getCantonsByProvince = (province : GetCantonsInput) =>{
    return `
    query {
        getCantonsByProvinceByCode(getCantonsByProvinceIdData : 
            { 
                provinceId: "${province.idProvince}" 
            }
        ){
            success
            message
            description
            code
            data{
                id
                code
                canton
                idProvince
            }
        } 
    }`
}

export const getDistrictsByCanton = (canton : GetDistrictsInput) =>{
    return `
    query {
        getDistrictsByCantonCode(getDistrictsByCantonIdData: 
            { 
                cantonId: "${canton.idCanton}" 
            }
        ){
            success
            message
            description
            code
            data{
                id
                code
                district
                idCanton
              }
        } 
    }`
}

export const getNeighborhoodsByDistrict = (district : GetNeighborhoodsInput) =>{
    return `
    query {
        getNeighborhoodsByDistrictCode(getNeighborhoodsByDistrictData: 
            { 
                districtId: "${district.idDistrict}" 
            }
        ){
            success
            message
            description
            code
            data{
                id
                code
                neighborhood
                idDistrict
            }
        } 
    }`
}