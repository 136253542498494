import { Injectable } from '@angular/core';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { ActionSheetController, Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ChoosePicService {


  photoOptions : CameraOptions = {
    quality: 70,
    targetWidth: 400,
    targetHeight: 400,
    // allowEdit: true,
    correctOrientation: true,
    destinationType: this.camera.DestinationType.DATA_URL,
    encodingType: this.camera.EncodingType.JPEG,
    mediaType: this.camera.MediaType.PICTURE
  }

  galleryOptions : CameraOptions = {
    quality: 100,
    targetWidth: 1000,
    targetHeight: 1000,
    correctOrientation: true,
    destinationType: this.camera.DestinationType.DATA_URL,
    sourceType: this.camera.PictureSourceType.SAVEDPHOTOALBUM
  }

  constructor(
    public actionSheetController: ActionSheetController, 
    private camera: Camera,
    private platform: Platform) { }

  async chooseTypeAndTakePhoto(lang) {
    return new Promise( async (resolve)=>{
      const { ACTIONSHEET, BUTTONS } = lang;
      const actionSheet = await this.actionSheetController.create({
        header: ACTIONSHEET.SELECTFROM,
        buttons: [{
          text: ACTIONSHEET.CAMERA,
          icon: 'camera',
          handler: async () => {
            resolve('camera');
          }
        }, {
          text: ACTIONSHEET.GALLERY,
          icon: 'image',
          handler: async () => {
            resolve('gallery')
          }
        }, {
          text: BUTTONS.BTNCANCEL,
          icon: 'close',
          role: 'cancel',
          handler: () => {
            resolve(null);
          }
        }]
      });
      await actionSheet.present();
    })
  }

  takephoto(isGallery){
    const options = isGallery === 'gallery' ? this.galleryOptions : this.photoOptions;
    return new Promise(async (resolve,reject) => {
        try{
          const imageData = await this.camera.getPicture(options);
          if(imageData){
            resolve(`data:image/jpeg;base64,${imageData}`)
          }else{
            reject(null)
          }
        }catch(e){
          reject(null)
        }
    })
  }
}
