import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GetMeasureUnitsOutput, MeasureUnit } from 'src/constants/connections/measureUnits/measureUnits';
import { getMeasureUnitsService, getServiceUnitsTypes } from 'src/constants/connections/measureUnits/queries';
import { AlertService } from 'src/providers/alert/alert.service';
import { GraphqlConnectionService } from 'src/providers/graphql-connection/graphql-connection.service';
import { AndroidBackButtomService } from 'src/providers/navigation/android-back-button';
import { SessiondataService } from 'src/providers/sessiondata/sessiondata.service';

@Component({
  selector: 'app-unit-page',
  templateUrl: './unit-page.page.html',
  styleUrls: ['./unit-page.page.scss'],
})
export class UnitPagePage implements OnInit {

  lang;
  isLoading;

  @Input() choosen: MeasureUnit;
  @Input() isService;

  units: Array<MeasureUnit> = [];
  unitsUnmute: Array<MeasureUnit> = [];
  hasItems;

  defaultSelectedRadio = "";
  //Get value on ionChange on IonRadioGroup
  selectedRadioGroup:any;
  //Get value on ionSelect on IonRadio item
  selectedRadioItem:any;

  constructor(
    public modalCtrl: ModalController,
    private session: SessiondataService,
    private connection: GraphqlConnectionService,
    private alertService: AlertService,
    private backButton: AndroidBackButtomService
  ) { }

  ionViewDidEnter() { 
    this.registerActionButton(); 
  }

  ionViewWillLeave() { 
    this.backButton.deregisterAction(); 
  }

  registerActionButton(){
    this.backButton.registerAction(()=>{
      this.backButton.goBack().then(
        () => this.modalCtrl.dismiss(),
        error => console.log(error)
      );
    }, 100);
  }

  ngOnInit() {
    this.lang = this.session.languageMessage;
    this.selectedRadioItem = this.choosen && this.choosen.symbol || '';
    this.getMeasureUnits();
  }

  radioSelect(unit) {
    this.selectedRadioItem = unit;
  }

  save(unit:string = ''){
    const unitFull: MeasureUnit = this.units.find(e => e.symbol === unit);
    console.log(unitFull)
    this.modalCtrl.dismiss({
      unitFull
    });
  }


  async getMeasureUnits(){
    this.isLoading = true;
    
    try{

        this.getMeasureUnitsBackend();

    }catch(e){
      console.log(e);
    }
    
  }


  async getMeasureUnitsBackend(){
      const measures = this.isService ? getServiceUnitsTypes(): getMeasureUnitsService();
      try{
        const response = await this.connection.post(measures);
        this.isLoading = false;
        if(response.data){
          const  getMeasures : any = response.data;
          const { success, data, message } : GetMeasureUnitsOutput  = this.isService ? getMeasures.getServiceUnits : getMeasures.getProductUnits;
          if( success && data){
            const list : Array<MeasureUnit> = [...data];
            this.units = [...list];
            this.unitsUnmute= [...list];
            this.hasItems = this.unitsUnmute.length > 0;
            this.session.setData('measureUnits',[...this.unitsUnmute]);
            this.session.setData('lastTimeMeasureUnits', Date.now());
          }else{
            this.alertService.presentToast(message);
          }
        }else{
          this.alertService.presentToast(this.lang.ERRORS.MEASURE); 
        }
      }catch(e){
        this.isLoading = false;
        this.alertService.presentToast(this.lang.ERRORS.MEASURE); 
      }
  }


  async getLocalMeasureUnits(){
    this.isLoading = false;
    try{
      const list: any = await this.session.getData('measureUnits');
      if(list){
        this.units = [...list];
        this.unitsUnmute= [...list];
        this.hasItems = this.unitsUnmute.length > 0;
      }else{
        this.units = [];
        this.unitsUnmute= [];
        this.hasItems = false;
      }
    }catch(e){
      this.units = [];
      this.unitsUnmute= [];
      this.hasItems = false;
    }
  }

}
