import { NgModule } from '@angular/core';
import { ButtonRoundedComponent } from './button-rounded/button-rounded.component';
import { LargeButtonComponent } from './large-button/large-button.component';
import { IonicModule } from '@ionic/angular';
import { SkeletonListComponent } from './skeleton-list/skeleton-list.component';
import { CommonModule } from '@angular/common';
import { EmptyListComponent } from './empty-list/empty-list.component';
import { ListItemComponent } from './list-item/list-item.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertSelectorComponent } from './alert-selector/alert-selector.component';
import { CheckCustomComponent } from './check-custom/check-custom.component';
import { SimpleOpenerComponent } from './simple-opener/simple-opener.component';
import { PresenterButtonComponent } from './presenter-button/presenter-button.component';
import { ImageProfileSelectorComponent } from './image-profile-selector/image-profile-selector.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { SentInvoiceDataComponent } from './sent-invoice-data/sent-invoice-data.component';
import { AgencyRowComponent } from './agency-row/agency-row.component';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from 'src/directives/directives.module';
import { StepperComponent } from './stepper/stepper.component';
import { InfinteLoaderComponent } from './infinte-loader/infinte-loader.component';
import { ResumeInvoiceComponent } from './resume-invoice/resume-invoice.component';
import { ExonetLineComponent } from './exonet-line/exonet-line.component';

import { AppMaterialModule } from 'src/app/app.material.module';
import { MailBoxComponent } from './mail-box/mail-box.component';
import { StatusLineComponent } from './status-line/status-line.component';
import { VerifyNumberInput } from './verify-number-input/verify-number-input.component';
import { CalendarComponent } from './calendar/calendar';

@NgModule({
	declarations: [
        LargeButtonComponent,
        ButtonRoundedComponent,
        SkeletonListComponent,
        EmptyListComponent,
        ListItemComponent,
        CheckCustomComponent,
        AlertSelectorComponent,
        SimpleOpenerComponent,
        PresenterButtonComponent,
        ImageProfileSelectorComponent,
        ErrorMessageComponent,
        DropdownComponent,
        SentInvoiceDataComponent,
        AgencyRowComponent,
        StepperComponent,
        InfinteLoaderComponent,
        ResumeInvoiceComponent,
        ExonetLineComponent,
        MailBoxComponent,
        StatusLineComponent,
        VerifyNumberInput,
        CalendarComponent,
    ],
	imports: [
        IonicModule.forRoot({
            backButtonText: ''
        }),
        CommonModule,
        FormsModule,
        TranslateModule,
        DirectivesModule,
        ReactiveFormsModule,
        AppMaterialModule
    ],
	exports: [
        LargeButtonComponent,
        ButtonRoundedComponent,
        SkeletonListComponent,
        EmptyListComponent,
        ListItemComponent,
        CheckCustomComponent,
        AlertSelectorComponent,
        SimpleOpenerComponent,
        PresenterButtonComponent,
        ImageProfileSelectorComponent,
        ErrorMessageComponent,
        DropdownComponent,
        SentInvoiceDataComponent,
        AgencyRowComponent,
        StepperComponent,
        InfinteLoaderComponent,
        ResumeInvoiceComponent,
        ExonetLineComponent,
        MailBoxComponent,
        StatusLineComponent,
        VerifyNumberInput,
        StatusLineComponent, 
        CalendarComponent,
        AlertSelectorComponent
    ],
})
export class ComponentsModule {}
