import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
})
export class ErrorMessageComponent implements OnInit {

  @Input('hasError') hasError: any;
  @Input('message') message: any;
  @Input('classError') classError: string;

  constructor() {
  }

  ngOnInit() {
  }
}
