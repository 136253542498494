import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Client, GetUserClientsOutput } from 'src/constants/connections/client/client';
import { getClients } from 'src/constants/connections/client/queries';
import { AlertService } from 'src/providers/alert/alert.service';
import { EventCallbackService } from 'src/providers/event-callback/event-callback.service';
import { FilterListProvider } from 'src/providers/filter-list/filter-list';
import { GraphqlConnectionService } from 'src/providers/graphql-connection/graphql-connection.service';
import { AndroidBackButtomService } from 'src/providers/navigation/android-back-button';
import { SessiondataService } from 'src/providers/sessiondata/sessiondata.service';
import { TimeProviderService } from 'src/providers/time-provider/time-provider.service';
import { NewClientPage } from '../new-client/new-client.page';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.page.html',
  styleUrls: ['./clients.page.scss'],
})

export class ClientsPage implements OnInit {

  @Input() from: number;
  @Input() invoiceType: string;

  clients: Array<Client> = []

  clientsUnmute: Array<any> = [];
  searchTerm: any = '';
  hasItems: boolean = false;
  isLoading: boolean = false;
  hasInternet: boolean = true;
  hasItemsFilterList: boolean = true;

  lang
  constructor(
    public filterList: FilterListProvider,
    public modalCtrl: ModalController,
    private router : Router,
    private sessionData: SessiondataService,
    private connection: GraphqlConnectionService,
    private alertService: AlertService,
    private timeValidate: TimeProviderService,
    private callbackService : EventCallbackService,
    private backButton: AndroidBackButtomService,
    private location: Location) { }

  ngOnInit() {
    this.lang = this.sessionData.getJsonLanguages(this.sessionData.currentlanguage);

  }

  ionViewDidEnter() { 
    this.registerActionButton(); 
  }

  registerActionButton(){
    this.backButton.registerAction(()=>{
      this.backButton.goBack().then(
        () => (this.from === 1) ? this.redirectOnboard() : this.backButton.goBackLocation(this.location),
        error => console.log(error)
      );
    }, 100);
  }

  setFilteredItems(){
    const shouldLookId = isNaN(this.searchTerm);
    if(shouldLookId){
      this.clients = this.filterList.filterItemsClient(this.searchTerm, this.clientsUnmute);
    }
    else{
      this.clients = this.filterList.filterItems(this.searchTerm,'identification',this.clientsUnmute);
    }
      
    this.hasItemsFilterList = this.clients.length > 0;

  }

  redirectOnboard(client = null){
    if(this.from === 1){
      this.modalCtrl.dismiss({
        client
      });
    }else{
      this.router.navigate(['home-dash-board']);
    }
  }

  openProfile(client,index){

    if(this.from === 1){
      this.modalCtrl.dismiss({
        client
      });
    }else{
      this.active(client,false,index);
    }
  }

  setNoClient(){
  /*  var noClient:Client = {
      name:"Sin Cliente",
      tradeName: "Sin Cliente",
      lastName: "Sin Cliente",
      identification:"00",
      identificationType:"00",
      businessName: "",
      movilePhone:{
        nationalNumber:"",
        e164:"",
        countryCode:""
      },
      landLinePhone:{
        nationalNumber:"",
        e164:"",
        countryCode:""
      },
      clientType:"0",
      societyType:"0",
      profilePicture:"",
      address:{
        province:"",
        canton:"",
        district:"",

        exactAddress:""
      }


    }
    */
    if(this.from === 1){
      this.modalCtrl.dismiss({
        client:"0"
      });
    }else{
      
    }
  }

  async active(client=null,from=true, index=-1){

    const modal = await this.modalCtrl.create({
      component: NewClientPage,
      cssClass: 'normal-modal',
      componentProps: {
        from,
        client
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if(data && data.client){
      const client: Client = data.client;
      const getCrudData = this.callbackService.getCrudObject();
      this.callbackService.handleEventCallback(data.action,{
        [getCrudData.ADD]:  this.addClient.bind(this,client),
        [getCrudData.EDIT]: this.editClient.bind(this,client,index),
        [getCrudData.DELETE]: this.deleteClient.bind(this,index)
      })
    }
  }

  addClient(client){
    this.clients.push(client);
    this.clientsUnmute.push(client);
    this.hasItems = this.clientsUnmute.length > 0;
    this.sessionData.setData('userlist',[...this.clientsUnmute]);
  }

  editClient(client,index){
    this.clients[index] = {...client};
    this.clientsUnmute[index] = {...client};
    this.hasItems = this.clientsUnmute.length > 0;
    this.sessionData.setData('userlist',[...this.clientsUnmute]);
  }

  deleteClient(index){
    this.clients.splice(index,1);
    this.clientsUnmute.splice(index,1);
    this.hasItems = this.clientsUnmute.length > 0;
    this.sessionData.setData('userlist',[...this.clientsUnmute]);
  }

  doInfinite(event){
    setTimeout(()=>{
      this.clients = [...this.clients, ...this.clientsUnmute];
      event.target.complete();
    },4000)
  }

  ionViewWillLeave(){
    this.sessionData.setData('userlist',[...this.clientsUnmute]);
    this.backButton.deregisterAction(); 
  }

  async ionViewWillEnter(){
    this.isLoading = true;
    try{
      const time : any = await this.sessionData.getData('lastTimeClientList');
      if(time){
        const validated = this.timeValidate.isLessThanXMins(new Date(), new Date(time), 1);
        validated ? this.getBackendClients() : this.getLocalClients();
      }else{
        this.getBackendClients();
      }
    }catch(error){
      this.getBackendClients();
    }
  }

  async getBackendClients(){
    const query = getClients();
    try{
      const response = await this.connection.post(query, true);
      this.isLoading = false;
      if(response.data){
        const { getUserClients } : any = response.data;
        const { success, data, message } : GetUserClientsOutput  = getUserClients;
        if( success && data){
          const list: Array<Client> = data;
          this.clients = [...list];
          this.clientsUnmute= [...list];
          this.hasItems = this.clientsUnmute.length > 0;
          this.sessionData.setData('userlist',[...this.clientsUnmute]);
          this.sessionData.setData('lastTimeClientList', Date.now());
        }else{
          this.alertService.presentToast(message);
        }
      }else{
        this.alertService.presentToast(this.lang.ERRORS.CLIENTSGET); //TODO response.errors
      }
    }catch(e){
      this.isLoading = false;
      this.alertService.presentToast(this.lang.ERRORS.CLIENTSGET); 
    }
  }

  async getLocalClients(){
    this.isLoading = false;
    try{
      const list: any = await this.sessionData.getData('userlist');
      if(list){
        this.clients = [...list];
        this.clientsUnmute= [...list];
        this.hasItems = this.clientsUnmute.length > 0;
      }else{
        this.clients = [];
        this.clientsUnmute= [];
        this.hasItems = false;
      }
    }catch(e){
      this.clients = [];
      this.clientsUnmute= [];
      this.hasItems = false;
    }
  }

}
