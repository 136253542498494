import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { Billing } from 'src/constants/connections/business/billing';
import { Business } from 'src/constants/connections/business/business';
import { Province } from 'src/constants/connections/places/places';
import { PreRegisterUserInput } from 'src/constants/connections/register/register';
import { DollarCurrencyExchangeData } from 'src/constants/connections/taxRate/taxrates';
import { User } from 'src/constants/connections/user/user';

declare var require: any;

@Injectable({
  providedIn: 'root'
})
export class SessiondataService {
  currentlanguage = '';
  public languageMessage;
  public currentUser;
  registerNumber : PreRegisterUserInput;
  public user: User;
  business: Business;
  billing: Billing;
  public currency: DollarCurrencyExchangeData;
  public token: any = '';
  gotoCompany = false;

  private provinces: Array<Province> = [];

  constructor(private storage: Storage, private translate: TranslateService) {
    this.languageMessage = this.getJsonLanguages(this.currentlanguage);
  }

  getJsonLanguages(lang){
    switch(lang){
      case 'es' : return require('src/assets/i18n/es.json');
      case 'en' : return require('src/assets/i18n/en.json');
      default : return require('src/assets/i18n/es.json');
    }
  }

  public getLanguage(){
    return this.languageMessage;
  }

  setData(key, data): Promise<boolean>{
    return new Promise(async (resolve)=>{
      try{
        const response: any = await this.storage.set(key, data);
        resolve(response? true: false);
      }catch(e){
        resolve(false);
      }
    })
  }

  getData(key){
    return new Promise(async (resolve,reject)=>{
      try{
          const response: any = await this.storage.get(key);
          resolve(response);
      }catch(e){
        reject(e);
      }
    })
  }

  removeData(key) : Promise<boolean>{
    return new Promise(async (resolve)=>{
      try{
        const response: any = await this.storage.remove(key);
        resolve(true);
      }catch(e){
        resolve(false);
      }
    })
  }

  clearAllData() : Promise<boolean>{
    return new Promise(async (resolve)=>{
      try{
        const response: any = await this.storage.clear();
        resolve(true);
      }catch(e){
        resolve(false);
      }
    })
  }


  setRegisterNumber(registerNumber : PreRegisterUserInput){
    this.registerNumber = registerNumber;
  }

  getRegisterNumber(){
    return this.registerNumber;
  }


  setUser(user: User){
    this.user = user;
  }

  getUser(){
    return this.user;
  }

  setBusiness(business: Business){
    this.business = business;
  }

  getBusiness(){
    return this.business;
  }

  setBilling(billing: Billing){
    this.billing = billing;
  }

  getBilling(){
    return this.billing;
  }

  setProvinces(provinces: Array<Province> = []){
    this.provinces = provinces;
  }

  getProvinces(){
    return this.provinces;
  }

  getToken(){
    return new Promise(async (resolve, reject)=>{
      try{
        const response: any = await this.getData('token');
        resolve(response);
      }catch(e){
        reject(null)
      }
    })
  }
}
