

export enum DocumentATVStatusEnum {
    PENDING = 'pending', 
    ACCEPTED = 'accepted', 
    REFUSED ='refused'}

export enum DocumentStatusEnum {
    PENDING = 'partial', 
    ACCEPTED = 'invalid', 
    REFUSED ='completed'
}


export class AttachedDocuments {

  xmlResponseInvoiceUrl: string;

  xmlInvoiceUrl: string;

  pdfInvoiceUrl: string;

}

export class InboxDocType {

  code!: string;

  name!: string;

}

export interface GetUserMailBoxOutput {
    success: Boolean;
    message: String;
    description: String;
    code: Number;
    data: Array<DocumentF>;
}

export interface UpdateUserMailBoxOutput {
    success: Boolean;
    message: String;
    description: String;
    code: Number;
    data: DocumentF;
}

export class DocumentF {
    readonly _id!: string;

    IDUser!: string;
  
    status!:DocumentStatusEnum;
  
    atvStatus!: DocumentATVStatusEnum;
  
    consecutive!: string;
  
    receiver!: string;
  
    issuer!: string;
  
    issue!: string;
  
    key!: string;
  
    type: InboxDocType;
  
    issuedDate!: string;
  
    attachements:AttachedDocuments;
  
}

export interface SpendType {
    code: String;
    description: String;
}

export class UpdateDocumentStatusInput {
    documentID: String;
    documentStatus:DocumentStatusEnum;
    spendingType:String;
    economicActivity:String;
}