import {NgModule} from '@angular/core';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';


@NgModule({
  imports: [
    MatProgressSpinnerModule, MatChipsModule,
    MatDialogModule, MatDatepickerModule,
  ],
  exports: [
    MatProgressSpinnerModule, MatChipsModule,
    MatDialogModule, MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule,
    MatIconModule
  ]
})
export class AppMaterialModule {}
