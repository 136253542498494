import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-large-button',
  templateUrl: './large-button.component.html',
  styleUrls: ['./large-button.component.scss'],
})
export class LargeButtonComponent implements OnInit {

  textToPresent = "";
  noText = true;
  @Input('icon') icon: string;
  @Input('buttonClassName') buttonClassName: string;
  @Input('text') text: string;
  @Input('placeholder') placeholder: string;
  @Input('textClass') textClass: string;
  
  @Output() ButtonClick = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    this.textToPresent = (this.text && this.text !== '') ? this.text : this.placeholder;
    this.noText = !this.text || this.text === '';
  }

  valueChanged(event){
    this.ButtonClick.emit(event);
  }

  ngDoCheck(){ 
    this.textToPresent = (this.text && this.text !== '') ? this.text : this.placeholder;
    this.noText = !this.text || this.text === '';
  }

}
