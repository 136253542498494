import { AddAgencyInput, DeleteAgencyInput } from "./agency";

export function addAgency(agency: AddAgencyInput){

    const { agencyName, address, phone } = agency;

    const { country, province, address: myAdress } = address;

    const { e164, nationalNumber, countryCode } = phone;
    

    return `mutation {
        addAgency(addAgencyData : {
            agencyName: "${agencyName}"
            address: {
                country: "${country}"
                province: "${province}"
                address: "${myAdress}"
            }
            phone:{
                e164: "${e164}"
                nationalNumber: "${nationalNumber}"
                countryCode: "${countryCode}"
            }
            }){
                success
                message
                code
                data {
                    _id
                    IDUser
                    agencyName
                    address{
                        country
                        province
                        address
                    }
                    phone{
                        e164
                        nationalNumber
                        countryCode
                    }
                }
            }
        }`
    }

export function getAgencies(){
    return `
        query{
            getUserAgencies
            {
                success
                message
                description
                code
                data{
                    _id
                    IDUser
                    agencyName
                    address{
                        country
                        province
                        address
                    }
                    phone{
                        e164
                        nationalNumber
                        countryCode
                    }
                }
            }
        }
    `
}

export function deleteAgency(agency: DeleteAgencyInput){
    return `mutation {
        deleteUserAgency(deleteAgencyData : {
            agencyID : "${agency.agencyID}"
            }){
                success
                message
                code
                description
            }
        }`
}

export function updateAgency(agency: AddAgencyInput){

    const { _id, agencyName, address, phone } = agency;

    const { country, province, address: myAdress } = address;

    const { e164, nationalNumber, countryCode } = phone;

    return `mutation {
        updateUserAgency(updateAgencyData : {
            _id: "${_id}"
            agencyName: "${agencyName}"
            address: {
                country: "${country}"
                province: "${province}"
                address: "${myAdress}"
            }
            phone:{
                e164: "${e164}"
                nationalNumber: "${nationalNumber}"
                countryCode: "${countryCode}"
            }
            }){
                success
                message
                code
                data {
                    _id
                    IDUser
                    agencyName
                    address{
                        country
                        province
                        address
                    }
                    phone{
                        e164
                        nationalNumber
                        countryCode
                    }
                }
            }
        }`
}

