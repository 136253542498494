import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, 
  ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { NewClientPageRoutingModule } from './new-client-routing.module';

import { NewClientPage } from './new-client.page';
import { ComponentsModule } from 'src/components/components.module';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from 'src/directives/directives.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NewClientPageRoutingModule,
    ComponentsModule,
    TranslateModule,
    ReactiveFormsModule,
    DirectivesModule
  ],
  declarations: [NewClientPage]
})
export class NewClientPageModule {}
