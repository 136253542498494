import { Injectable } from '@angular/core';
import { Agency } from 'src/constants/connections/agency/agency';
import { Canton, District, GetCantonsInput, GetCantonsOutput, GetDistrictsInput, GetDistrictsOutput, GetNeighborhoodsInput, GetNeighborhoodsOutput, GetProvincestOutput, Neighborhood, Province } from 'src/constants/connections/places/places';
import { getCantonsByProvince, getDistrictsByCanton, getNeighborhoodsByDistrict, getProvinces } from 'src/constants/connections/places/queries';
import { GraphqlConnectionService } from '../graphql-connection/graphql-connection.service';
import { SessiondataService } from '../sessiondata/sessiondata.service';

@Injectable({
  providedIn: 'root'
})
export class PlacesService {

  constructor(
    private sessionData: SessiondataService,
    private connection: GraphqlConnectionService) {

  }

  async getProvincesService(){
    const query = getProvinces();
    try{
      const response = await this.connection.post(query);
      if(response.data){
        const { getProvinces } : any = response.data;
        const { success, data } : GetProvincestOutput = getProvinces;
        if( success && data ){
          const list: Array<Province> = data;
          this.sessionData.setData('provinces',list);
          return list;
        }else{
          return null
        }
      }else{
        return null;
      } 
    }catch(e){
      return null;
    }
  }

  async getCantonService(cantonsInput: GetCantonsInput){
    const query = getCantonsByProvince(cantonsInput);
    try{
      const response = await this.connection.post(query);
      if(response.data){
        const { getCantonsByProvinceByCode } : any = response.data;
        const { success, data } : GetCantonsOutput= getCantonsByProvinceByCode;
        if( success && data ){
          const list: Array<Canton> = data;
          return list;
        }else{
          return null
        }
      }else{
        return null;
      } 
    }catch(e){
      return null;
    }
  }

  async getDistrictService(canton: GetDistrictsInput){
    const query = getDistrictsByCanton(canton);
    try{
      const response = await this.connection.post(query);
      if(response.data){
        const { getDistrictsByCantonCode } : any = response.data;
        const { success, data } : GetDistrictsOutput = getDistrictsByCantonCode;
        if( success && data ){
          const list: Array<District> = data;
          return list;
        }else{
          return null
        }
      }else{
        return null;
      } 
    }catch(e){
      return null;
    }
  }

  async getNeighborhoodsService(district: GetNeighborhoodsInput){
    const query = getNeighborhoodsByDistrict(district);
    try{
      const response = await this.connection.post(query);
      if(response.data){
        const { getNeighborhoodsByDistrictCode } : any = response.data;
        const { success, data } : GetNeighborhoodsOutput = getNeighborhoodsByDistrictCode;
        if( success && data ){
          const list: Array<Neighborhood> = data;
          return list;
        }else{
          return null
        }
      }else{
        return null;
      } 
    }catch(e){
      return null;
    }
  }

  async getLocalProvinces(){
    try{
      const response = await this.sessionData.getData('provinces');
      return response ? response : [];
    }catch{
      return []
    }
  }


  async getCRCProvinces(){
    try{
      const arrayOfProvinces = this.sessionData.getProvinces();
      if(arrayOfProvinces.length <= 0){
        const provinces: any = await this.getLocalProvinces();
        if(provinces.length > 0){
          this.sessionData.setProvinces(provinces)
          return provinces
        }else{
          const response: any = await this.getProvincesService();
          this.sessionData.setProvinces(response);
          return response;
        }
      }else{
        return arrayOfProvinces;
      }
    }catch(e){
      this.sessionData.setProvinces([]);
      return []
    }
  }

  createRadioPlaces(array,type,key,code) {
    const theNewInputs = [];
    for (var element of array) {
      theNewInputs.push(
        {
          type: 'radio',
          label: element[type],
          value: element[type],
          checked: false,
          key: element[key],
          code: element[code]

        }
      );
    }
    return theNewInputs;
  }
}
