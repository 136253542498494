import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Canton, District, GetCantonsInput, GetDistrictsInput, GetNeighborhoodsInput, Neighborhood, Province } from 'src/constants/connections/places/places';
import { AddProviderInput, AddProviderOutput, AddressTypeInput, DeleteProviderInput, InputActivityType, Provider, ProviderTypeList } from 'src/constants/connections/provider/provider';
import { getSimpleID } from 'src/constants/connections/register/queries';
import { Activity, AE, AEOutput } from 'src/constants/connections/user/id';
import { User } from 'src/constants/connections/user/user';
import { patterns } from 'src/constants/pattern';
import { AlertService } from 'src/providers/alert/alert.service';
import { AndroidPermissionsService } from 'src/providers/android-permissions/android-permissions.service';
import { ChoosePicService } from 'src/providers/choose-pic/choose-pic.service';
import { GraphqlConnectionService } from 'src/providers/graphql-connection/graphql-connection.service';
import { LoadingService } from 'src/providers/loading/loading.service';
import { PlacesService } from 'src/providers/places/places.service';
import { SessiondataService } from 'src/providers/sessiondata/sessiondata.service';
import { InputValidators } from 'src/providers/validators/inputvalidators';
import { countries } from 'src/constants/countries';
import { addProvider, deleteProvider, updateProvider } from 'src/constants/connections/provider/queries';

@Component({
  selector: 'app-new-provider',
  templateUrl: './new-provider.page.html',
  styleUrls: ['./new-provider.page.scss'],
})
export class NewProviderPage implements OnInit {

  @Input() from: boolean;
  @Input() provider: Provider;

  providerTypeList = ProviderTypeList;

  form = this.formBuilder.group({
    providerType: [ProviderTypeList.PERSON],
    identificationType: [''],
    identification: ['', [
      Validators.required,
      InputValidators.identityValidator
    ]],
    name: [{
      value: '',
      disabled: true
    }, [
      Validators.required
    ]],
    lastName: [''],
    email: ['', [
      Validators.required,
      Validators.pattern(patterns.email)
    ]],
    landLinePhone: ['', [
      InputValidators.phoneNumerValidator
    ]],
    movilePhone: ['', [
      InputValidators.phoneNumerValidator
    ]],
    address: this.formBuilder.group({
      province: ['', Validators.required],
      canton: ['', Validators.required],
      district: ['', Validators.required],
      neighborhood: ['', Validators.required],
      exactAddress:['', Validators.required]
    }),
    activities: this.formBuilder.array([])
  });

  buttons = [];

  selectedCountry: any = "";
  selectedID: any = ""
  selectedState:any = '';
  selectedCity:any = '';
  selectedTown:any = '';
  selectedNeighborhood:any = '';
  fullCountry;
  

  hasErrorCountry: boolean = false;
  hasErrorCity: boolean = false;
  hasErrorID: boolean = false;
  hasErrorTown: boolean = false;
  hasErrorCAnt: boolean = false;
  hasErrorNeigh: boolean;
  hasErrorState: boolean;

  get providerType() { return this.form.get('providerType'); }
  get identification() { return this.form.get('identification'); }
  get identificationType() { return this.form.get('identificationType'); }
  get name() { return this.form.get('name'); }
  get lastName() { return this.form.get('lastName'); }
  get email() { return this.form.get('email'); }
  get landLinePhone() { return this.form.get('landLinePhone'); }
  get movilePhone() { return this.form.get('movilePhone'); }
  get address(): AddressTypeInput { return this.form.get('address').value as AddressTypeInput; }
  get addressField() { return this.form.get('address').get('exactAddress'); }
  get activities(): Activity[] { return this.form.get('activities').value; }

  clickedImage: any = "";
  lang;

  user: User;
  provinces: any[] = [];
  cantones: any[] = [];
  distrits: any[] = [];
  neighborhoods: any[] = [];

  isLoading: Boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private alertService: AlertService,
    private choosePic: ChoosePicService,
    private sessiondata: SessiondataService,
    private permission: AndroidPermissionsService,
    private loadingController: LoadingService,
    private connection: GraphqlConnectionService,
    private formBuilder: FormBuilder,
    private places: PlacesService,
  ) { }

  ngOnInit() {
    this.lang = this.sessiondata.getJsonLanguages(this.sessiondata.currentlanguage);
    this.getDataFromLocal();
    this.buttons = [
      {
        text: this.lang.BUTTONS.BTNCANCEL,
        role: 'cancel',
        cssClass: 'btnModalDismiss'
      }, {
        text: this.lang.BUTTONS.BTNACCEPT,
        cssClass: 'btnModalAcept'
      }
    ];
  }

  redirectProviderPage(provider = null, action = 'add') {
    this.modalCtrl.dismiss({
      provider,
      action
    })
  }

  async goToDelete() {
    // TODO agregar confirmación al eliminar
    this.isLoading = true;
    const { ERRORS } = this.lang;
    try {
      const provider: DeleteProviderInput = {
        providerID: this.provider._id
      }
      const mutation = deleteProvider(provider);
      const response = await this.connection.post(mutation, true);
      this.isLoading = false;
      if (response && response.data) {
        const { deleteProvider }: any = response.data;
        const { success, message }: AddProviderOutput = deleteProvider;
        if (success) {
          this.alertService.presentToast(message, 'primary');
          this.redirectProviderPage(this.provider, 'delete');
        } else {
          this.alertService.presentToast(message);
        }
      } else {
        this.alertService.presentToast(ERRORS.DELETINGPROVIDER);
      }
    } catch (e) {
      this.alertService.presentToast(ERRORS.DELETINGPROVIDER);
      this.isLoading = false;
    }
  }

  async goToSave() {
    this.isLoading = true;
    const provider: AddProviderInput = this.getProvider();

    const { ERRORS } = this.lang;
    try {
      const mutation = this.from ? addProvider(provider) : updateProvider(provider);
      console.log("ProviderPage "+mutation)
      const response = await this.connection.post(mutation, true);
      if (response && response.data) {
        const { addProvider, updateProvider }: any = response.data;
        const { success, data, message, code, description }: AddProviderOutput = this.from ? addProvider : updateProvider;
        if (success && data) {
          if (!code) {
            this.alertService.presentToast(description, 'warning', 4000);
            setTimeout(() => this.alertService.presentToast(message, 'primary', 2000), 5000)
          } else {
            this.alertService.presentToast(message, 'primary');
          }
          const savedProvider: Provider = data;
          this.redirectProviderPage(savedProvider, this.from ? 'add' : 'edit');
        } else {
          this.alertService.presentToast(message, 'warning');
        }
      } else {
        this.alertService.presentToast(this.from ? ERRORS.UPDATEPROVIDER : ERRORS.ADDPROVIDER);
      }
      this.isLoading = false;
    } catch (e) {
      this.alertService.presentToast(this.from ? ERRORS.UPDATEPROVIDER : ERRORS.ADDPROVIDER);
      this.isLoading = false;
    }
  }

  getProvider(): AddProviderInput {

    let providerByType: any = {};
    if (this.providerType.value === ProviderTypeList.PERSON) {
      providerByType = {
        name: this.name.value,
        lastName: this.lastName.value,
        businessName: this.name.value,
        tradeName: this.name.value
      }
    } else {
      providerByType = {
        businessName: this.name.value,
        tradeName: this.lastName.value,
        name: this.name.value,
        lastName: this.name.value
      }
    }
    // TODO verificar si en address no hay que mandar los IDs y no los textos
    const provider: AddProviderInput = {
      address: this.address,
      movilePhone: {
        e164: "+506"+`${this.movilePhone.value}`,
        nationalNumber: this.movilePhone.value,
        countryCode: "506"
      },
      email: this.email.value,
      landLinePhone: {
        e164: "+506"+`${this.landLinePhone.value}`,
        nationalNumber: this.landLinePhone.value,
        countryCode: "506"
      },
      providerType: this.providerType.value,
      ...providerByType,
      identification: this.identification.value,
      identificationType: this.identificationType.value,
      profilePicture: this.clickedImage,
      activities: this.activities
    }
    return provider;
  }

  async getDataFromLocal() {
    try {

      this.user = this.sessiondata.getUser()
      //const provs = await this.places.getCRCProvinces();
      //this.provinces = this.places.createRadioPlaces(provs, 'province', 'codProvince','');
      await this.requestProvinces();
      if (!this.user) {
        let localUser;
        localUser = await this.sessiondata.getData('registerUser');
        if (!localUser)
          localUser = await this.sessiondata.getData('isLoggedIn');
        this.user = localUser;
      }
      if(!this.from){
        this.selectedState = await this.provinces.find(p => p.code == this.provider.address.province.code);
        const province: GetCantonsInput = {
          idProvince: this.selectedState.code
        }
        await this.requestCantones(province);
        this.selectedCity = this.cantones.find(p => p.code == this.provider.address.canton.code);
        const canton: GetDistrictsInput = {
          idCanton: this.selectedCity.key
        }
        await this.requestDistricts(canton);
        this.selectedTown = this.distrits.find(p => p.code == this.provider.address.district.code);
        const district: GetNeighborhoodsInput = {
          idDistrict: this.selectedTown.key
        }
        await this.requestNeighborhoods(district);
        this.selectedNeighborhood = this.neighborhoods.find(p => p.code == this.provider.address.neighborhood.code);
        this.loadForm();
      }

    } catch (e) {
      console.log(e);
    }
  }

  // Carga los valores del provider a editar
  async loadForm() {
    const {
      landLinePhone,
      movilePhone,
      email,
      address,
      name,
      lastName,
      identification,
      identificationType,
      businessName,
      tradeName,
      profilePicture,
      providerType,
      activities
    } = this.provider || {};

    console.log("providertoLoad  "+JSON.stringify(this.provider))

    this.clickedImage = profilePicture || '';

    this.form.patchValue({
      providerType: providerType,
      identification: identification,
      identificationType: identificationType,
      name: providerType === ProviderTypeList.PERSON ? name : businessName,
      lastName: providerType === ProviderTypeList.PERSON ? lastName : tradeName,
      email: email || '',
      landLinePhone: landLinePhone && landLinePhone.nationalNumber || '',
      movilePhone: movilePhone && movilePhone.nationalNumber || '',
    });
    this.form.get('address').patchValue({
      province: address.province || '',
      exactAddress: address.exactAddress || '',
      canton: address.canton || '',
      district: address.district || '',
      neighborhood: address.neighborhood || ''
    });
    let activitiesForm: FormArray = this.form.get('activities') as FormArray;
    activities.forEach((act: InputActivityType) => {
      activitiesForm.push(this.formBuilder.group({
        state: act.state,
        description: act.description,
        code: act.code.toString(),
        type: act.type,
      } as InputActivityType));
    });

    if (this.provider) {
      //this.selectedCountry = address.country;
      //this.fullCountry = countries.find(country => country.value == this.selectedCountry);
    }
  }

  async findIds() {
    const id = this.identification.value;
    if (!id) {
      this.alertService.presentToast(this.lang.NEW_PROVIDER.ERROR_EMPTY_IDENTIFICACION);
      this.form.patchValue({
        identification: ''
      });
    } else if (this.user && this.user.identification.includes(id)) {
      this.alertService.presentToast(this.lang.OWNERPROFILE.ERROR_ID_3);
      this.form.patchValue({
        identification: ''
      });
    } else {
      this.loadingController.loadingPresent(this.lang.OWNERPROFILE.LOADING);
      const query = getSimpleID(id);
      try {
        const response = await this.connection.post(query);
        if (response.data) {
          this.loadingController.loadingDismiss();
          const { getBillingUserStatus }: any = response.data;
          const { success, data }: AEOutput = getBillingUserStatus;
          if (success && data) {
            this.manageData(data);
          } else {
            this.alertService.presentToast(this.lang.OWNERPROFILE.ERROR_ID_2);
          }
        } else {
          this.loadingController.loadingDismiss();
          if (response.errors) {
            console.log(response.errors)
          } else {
            this.alertService.presentToast(this.lang.OWNERPROFILE.ERROR_ID_2);
          }
        }
      } catch (e) {
        this.alertService.presentToast(this.lang.OWNERPROFILE.ERROR_ID_2);
        this.loadingController.loadingDismiss();
      }
    }
  }

  manageData(provider: AE) {
    (this.form.controls['activities'] as FormArray).clear();
    let activities: FormArray = this.form.get('activities') as FormArray;
    // Ordeno actividades por estado A
    provider.actividades.sort((a, b) => {
      var estadoA = a.tipo.toUpperCase();
      var estadoB = b.tipo.toUpperCase();
      return (estadoA < estadoB) ? -1 : (estadoA > estadoB) ? 1 : 0;
    });
    provider.actividades.forEach((act: Activity) => {
      activities.push(this.createItemActivity(act));
    });
    this.form.patchValue({
      name: provider.nombre,
      identificationType: provider.tipoIdentificacion,
    });
  }

  // Crea un item de actividad para el array de actividades del proveedor
  createItemActivity(act: Activity): FormGroup {
    return this.formBuilder.group({
      state: act.estado,
      description: act.descripcion,
      code: act.codigo.toString(),
      type: act.tipo,
    } as InputActivityType);
  }

  async choosePicture() {
    try {
      const wantPic = await this.choosePic.chooseTypeAndTakePhoto(this.lang);
      if (wantPic) {
        try {
          const permission = await this.permission.hasPermissions(this.permission.permission2s());
          if (permission) {
            const image = await this.choosePic.takephoto(wantPic);
            if (image) {
              this.clickedImage = image;
            }
          } else {
            const askPermission = await this.permission.requestPermissions(this.permission.permission2s());
            if (askPermission) {
              const image = await this.choosePic.takephoto(wantPic);
              if (image) {
                this.clickedImage = image;
              }
            } else {
              console.log('error')
            }
          }
        } catch (e) {
          console.log(e)
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  async stateAlert() {
    try {
      const response: any = await this.alertService.showListAlert(this.lang.ALERT.CITY, this.buttons, 'customAlert', this.provinces, this.selectedState);
      if (response) {
        const fullState = this.provinces.find(e => e.value.includes(response))
        this.selectedState = fullState
        this.form.get('address').patchValue({province:{name:fullState.label,code:fullState.code}})
        const province: GetCantonsInput = {
          idProvince: fullState.key
        }
        this.requestCantones(province);
        this.cleanSelects('byProvince');
        this.hasErrorState = false;
      }
      else {
        this.hasErrorState = this.selectedState === '';
      }
    } catch (e) {
      this.hasErrorState = this.selectedState === '';
    }
  }

  async cityAlert() {
    try {
      const response: any = await this.alertService.showListAlert(this.lang.ALERT.CITY2, this.buttons, 'customAlert', this.cantones, this.selectedCity);
      if (response) {
        const fullCity = this.cantones.find(e => e.value.includes(response))
        this.selectedCity = fullCity
        this.form.get('address').patchValue({canton:{name:fullCity.label,code:fullCity.code}})
        const canton: GetDistrictsInput = {
          idCanton: fullCity.key
        }
        this.requestDistricts(canton);
        this.cleanSelects('byCanton');
        this.hasErrorCity = false;
      }
      else {
        this.hasErrorCity = this.selectedCity === '';
      }
    } catch (e) {
      this.hasErrorCity = this.selectedCity === '';
    }
  }

  async townAlert() {
    try {
      const response: any = await this.alertService.showListAlert(this.lang.ALERT.CITY3, this.buttons, 'customAlert', this.distrits, this.selectedTown);
      if (response) {
        const fullTown = this.distrits.find(e => e.value.includes(response))
        this.selectedTown = fullTown
        this.form.get('address').patchValue({district:{name:fullTown.label,code:fullTown.code}})
        const district: GetNeighborhoodsInput = {
          idDistrict: fullTown.key
        }
        this.requestNeighborhoods(district);
        this.cleanSelects('byDistrito');
        this.hasErrorTown = false;
      } else {
        this.hasErrorTown = this.selectedTown === '';
      }
    } catch (e) {
      this.hasErrorTown = this.selectedTown === '';
    }
  }

  async neighborhoodAlert() {
    try{
      const response: any = await this.alertService.showListAlert( this.lang.ALERT.CITY3, this.buttons,'customAlert',this.neighborhoods, this.selectedNeighborhood);
      if(response){
        const fullNeighbor = this.neighborhoods.find(e => e.value.includes(response))
        this.selectedNeighborhood= fullNeighbor;
        this.form.get('address').patchValue({neighborhood:{name:fullNeighbor.label,code:fullNeighbor.code}})
        this.hasErrorNeigh = false;
      }else{
        this.hasErrorNeigh = this.selectedNeighborhood === null;
      }
    }catch(e){
      this.hasErrorNeigh = this.selectedNeighborhood === null;
    }
  }

  async requestProvinces() {
    const province : Province = await this.places.getCRCProvinces();
    this.provinces = this.places.createRadioPlaces(province,'province','id','code');
  }

  async requestCantones(province: GetCantonsInput) {
    const tempCantones: Array<Canton> = await this.places.getCantonService(province);
    this.cantones =  this.places.createRadioPlaces(tempCantones,'canton','id','code');
  }

  async requestDistricts(canton: GetDistrictsInput) {
    const tempDistricts: Array<District> = await this.places.getDistrictService(canton);
    this.distrits =  this.places.createRadioPlaces(tempDistricts,'district','id','code');
  }

  async requestNeighborhoods(district: GetNeighborhoodsInput) {
    const tempNeighborhoods: Array<Neighborhood> = await this.places.getNeighborhoodsService(district);
    this.neighborhoods =  this.places.createRadioPlaces(tempNeighborhoods,'neighborhood','id','code');
  }

  getMessageError(value, type) {
    const forms = this.lang.FORMS;
    if (!!value.errors) {
      const { required, pattern } = value.errors
      if (required) {
        return forms.REQUIRED;
      } else if (!!pattern) {

        if (type == 'email') {
          return forms.EMAIL;
        }

        if (type == 'phone') {
          return forms.PHONE;
        }

        if (type == 'number') {
          return forms.ID;
        }

        return forms.REQUIRED;
      } else if (value.errors?.invalidPhone) {
        return forms.PHONE;
      } else if (value.errors?.identityPhone) {
        return forms.ID;
      }
    }
  }

  cleanSelects(key) {
    switch (key) {
      case "byCountry":
        this.form.get('address').patchValue({
          city: '',
          canton: '',
          district: ''
        });
        //this.selectedCant = '';
        this.selectedCity = '';
        this.selectedTown = '';
        this.provinces = [];
        this.cantones = [];
        this.distrits = [];
        this.hasErrorCAnt = false;
        this.hasErrorCity = false;
        this.hasErrorTown = false;
        break;
      case "byProvince":
        this.form.get('address').patchValue({
          canton: '',
          district: ''
        });
        //this.selectedCant = '';
        this.selectedCity = '';
        this.selectedTown = '';
        this.cantones = [];
        this.distrits = [];
        this.hasErrorCAnt = false;
        this.hasErrorTown = false;
        break;
      case "byCanton":
        this.form.get('address').patchValue({
          district: ''
        });
        this.selectedTown = '';
        this.distrits = [];
        this.hasErrorTown = false;
        break;
    }
  }

}
