import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-status-line',
  templateUrl: './status-line.component.html',
  styleUrls: ['./status-line.component.scss'],
})
export class StatusLineComponent implements OnInit {


  @Input('status') status: any;

  @Input('show') show: boolean;
  
  @Input('buttons') buttons: any;

  @Input('index') index: number;

  @Input('titleClass') titleClass: string;

  @Input('parentClass') parentClass: string;

  

  @Output() OnClick = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    console.log(this.buttons)
  }


  onClick(buttonName){
    this.OnClick.emit({buttonName, index: this.index});
  }

}
