
const email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phone = '^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$';
const numbers = '^[0-9]*$';
const onlyExonet = /^[a|A][l|L]([-])(\d{8}|[0-9])([-])([0-9]{2})$/



const patterns =  {
    email,
    phone,
    numbers,
    onlyExonet
}


export { patterns }