import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-check-custom',
  templateUrl: './check-custom.component.html',
  styleUrls: ['./check-custom.component.scss'],
})
export class CheckCustomComponent implements OnInit {


  @Input('data') data: any;
  @Input('labelClass') labelClass: any;
  @Input('parentClass') parentClass: any;
  @Input('disabled') disabled = false;
  
  

  @Output() OnClick = new EventEmitter<any>();


  constructor() { }

  ngOnInit() {
  }

  changed($event){
    if(this.disabled)return;
    const { detail } = $event;
    this.OnClick.emit(detail.checked);
  }

  setCheckedLabel(){
    if(this.disabled)return;
    this.OnClick.emit(!this.data.checked);
  }

}
