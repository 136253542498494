import { Component, Input, OnInit } from '@angular/core';
import { SummaryInvoiceOutput } from 'src/constants/connections/invoice/eProofType';
import { GetInvoiceSummaryResponseOutput } from 'src/constants/connections/invoice/responseTypes/atvInvoiceSummary';

@Component({
  selector: 'app-resume-invoice',
  templateUrl: './resume-invoice.component.html',
  styleUrls: ['./resume-invoice.component.scss'],
})
export class ResumeInvoiceComponent implements OnInit {

  @Input('invoice') invoice: GetInvoiceSummaryResponseOutput;

  @Input('show') show: boolean;

  @Input('langData') langData: any;

  @Input('IsTax') IsTax: boolean;

  @Input('IsSell') IsSell: boolean;

  @Input('currency') currency: any;

  tax = '';

  constructor() { }

  ngOnInit() {
    console.log("Resumen que llega"+JSON.stringify(this.invoice))
  }

}
