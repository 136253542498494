import { AddProviderInput, DeleteProviderInput, InputActivityType } from "./provider";

export function addProvider(provider: AddProviderInput) {

    const {
        providerType,
        landLinePhone,
        movilePhone,
        email,
        identification,
        identificationType,
        address,
        name,
        lastName,
        businessName,
        tradeName,
        profilePicture,
        activities
    } = provider;

    let activities2 = '';
    activities.forEach( (activity: InputActivityType) => {
        activities2 += `{
            state: "${activity.state}"
            type: "${activity.type}"
            code:   "${activity.code.toString()}"
            description: "${activity.description}"
        }`
    });

    return `mutation{
        addProvider(
            addProviderData : {
                providerType : "${providerType}"
                landLinePhone: {
                    e164:  "${landLinePhone.e164}"
                    nationalNumber:  "${landLinePhone.nationalNumber}"
                    countryCode:  "${landLinePhone.countryCode}"
                } 
                movilePhone: {
                    e164:  "${movilePhone.e164}"
                    nationalNumber:  "${movilePhone.nationalNumber}"
                    countryCode:  "${movilePhone.countryCode}"
                }
                email : "${email}"
                identification: "${identification}",
                identificationType: "${identificationType}",
                address: {
                    province: {
                        code: "${address.province.code}"
                        name: "${address.province.name}"
                    }
                    canton:  {
                        code: "${address.canton.code}"
                        name: "${address.canton.name}"
                    }
                    district:  {
                        code: "${address.district.code}"
                        name: "${address.district.name}"
                    }
                    neighborhood:  {
                        code: "${address.neighborhood.code}"
                        name: "${address.neighborhood.name}"
                    }
                    exactAddress: "${address.exactAddress}"
                }
                name : "${name}"
                lastName : "${lastName}"
                businessName : "${businessName}"
                tradeName : "${tradeName}"
                profilePicture : "${profilePicture}"
            }
        ){
            success
            message
            description
            code
            data{
                _id
                IDUser
                landLinePhone{
                    e164
                    nationalNumber
                    countryCode
                }
                movilePhone{
                    e164
                    nationalNumber
                    countryCode
                }
                email
                providerType
                address{
                    province{
                      code
                      name
                    }
                   canton{
                      code
                      name
                    }
                    district{
                      code
                      name
                    }
                    district{
                      code
                      name                      
                    }
                  neighborhood{
                  	code
                    name
                  }
                    exactAddress
                }
                name
                lastName
                identificationType
                identification
                businessName
                tradeName
                societyType
                profilePicture
                activities{
                    state
                    description
                    code
                    type
                  }
            }
        }
    }`
}

export function updateProvider(provider: AddProviderInput) {
    const {
        providerType,
        landLinePhone,
        movilePhone,
        email,
        identification,
        address,
        name,
        lastName,
        businessName,
        tradeName,
        profilePicture,
        activities
    } = provider;

    let activities2 = '';
    activities.forEach( (activity: InputActivityType) => {
        activities2 += `{
            state: "${activity.state}"
            type: "${activity.type}"
            code:   "${activity.code}"
            description: "${activity.description}"
        }`
    });

    return `mutation{
        updateProvider(
            updateProviderData : {
                providerType : "${providerType}"
                landLinePhone: {
                    e164:  "${landLinePhone.e164}"
                    nationalNumber:  "${landLinePhone.nationalNumber}"
                    countryCode:  "${landLinePhone.countryCode}"
                } 
                movilePhone: {
                    e164:  "${movilePhone.e164}"
                    nationalNumber:  "${movilePhone.nationalNumber}"
                    countryCode:  "${movilePhone.countryCode}"
                }
                email : "${email}"
                identification: "${identification}"
                address: {
                    province: {
                        code: "${address.province.code}"
                        name: "${address.province.name}"
                    }
                    canton:  {
                        code: "${address.canton.code}"
                        name: "${address.canton.name}"
                    }
                    district:  {
                        code: "${address.district.code}"
                        name: "${address.district.name}"
                    }
                    neighborhood:  {
                        code: "${address.neighborhood.code}"
                        name: "${address.neighborhood.name}"
                    }
                    exactAddress: "${address.exactAddress}"
                }
                name : "${name}"
                lastName : "${lastName}"
                businessName : "${businessName}"
                tradeName : "${tradeName}"
                profilePicture : "${profilePicture}"
            }
        ){
            success
            message
            description
            code
            data{
                _id
                IDUser
                landLinePhone{
                    e164
                    nationalNumber
                    countryCode
                }
                movilePhone{
                    e164
                    nationalNumber
                    countryCode
                }
                email
                providerType
                address{
                    province{
                      code
                      name
                    }
                   canton{
                      code
                      name
                    }
                    district{
                      code
                      name
                    }
                    district{
                      code
                      name                      
                    }
                  neighborhood{
                  	code
                    name
                  }
                    exactAddress
                }
                name
                lastName
                identificationType
                identification
                businessName
                tradeName
                societyType
                profilePicture
                activities{
                    state
                    description
                    code
                    type
                  }
            }
        }
    }`
}

export function deleteProvider(provider: DeleteProviderInput) {
    return `mutation{
        deleteProvider(
            deleteProviderData : {
                ProviderID: "${provider.providerID}"
            }
        ){
            success
            message
            description
            code
        }
    }`
}


export function getProviders() {
    return `
        query{
            getUserProviders
            {
                success
                message
                description
                code
                data{
                    _id
                    IDUser
                    email
                    providerType
                    name
                    lastName
                    identificationType
                    identification
                    businessName
                    tradeName
                    societyType
                    profilePicture
                    activities{
                        state
                        description
                        code
                        type
                      }
                    address{
                        province{
                          code
                          name
                        }
                       canton{
                          code
                          name
                        }
                        district{
                          code
                          name
                        }
                        district{
                          code
                          name                      
                        }
                      neighborhood{
                          code
                        name
                      }
                        exactAddress
                    }
                    movilePhone {
                        e164
                        nationalNumber
                        countryCode
                    }
                    landLinePhone {
                        e164
                        nationalNumber
                        countryCode
                    }
                }
            }
        }
    `
}