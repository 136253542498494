import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-simple-opener',
  templateUrl: './simple-opener.component.html',
  styleUrls: ['./simple-opener.component.scss'],
})
export class SimpleOpenerComponent implements OnInit {


  @Input('text') text: string;

  @Input('customClass') customClass: string;

  @Output() OnClick = new EventEmitter<any>();


  constructor() { }

  ngOnInit() {}

  onClick(){
    this.OnClick.emit();
  }

}
