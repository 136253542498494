import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { FilterListProvider } from 'src/providers/filter-list/filter-list';
import { AndroidBackButtomService } from 'src/providers/navigation/android-back-button';
import { SessiondataService } from 'src/providers/sessiondata/sessiondata.service';
import { Location } from '@angular/common';
import { GetUserProvidersOutput, Provider, ProviderTypeList } from 'src/constants/connections/provider/provider';
import { TimeProviderService } from 'src/providers/time-provider/time-provider.service';
import { getProviders } from 'src/constants/connections/provider/queries';
import { GraphqlConnectionService } from 'src/providers/graphql-connection/graphql-connection.service';
import { AlertService } from 'src/providers/alert/alert.service';
import { NewProviderPage } from '../new-provider/new-provider.page';
import { EventCallbackService } from 'src/providers/event-callback/event-callback.service';

@Component({
  selector: 'app-providers',
  templateUrl: './providers.page.html',
  styleUrls: ['./providers.page.scss'],
})
export class ProvidersPage implements OnInit {

  @Input() from: number;
  lang: any;

  providers: Array<Provider> = []

  providersUnmute: Array<Provider> = [];
  searchTerm: any = '';
  hasItems: boolean = false;
  isLoading: boolean = false;
  hasInternet: boolean = true;
  hasItemsFilterList: boolean = true;

  providerType = ProviderTypeList;

  constructor(
    public filterList: FilterListProvider,
    private sessionData: SessiondataService,
    private connection: GraphqlConnectionService,
    private backButton: AndroidBackButtomService,
    private router: Router,
    private alertService: AlertService,
    private callbackService: EventCallbackService,
    private timeValidate: TimeProviderService,
    private modalCtrl: ModalController,
    private location: Location

  ) { }

  ngOnInit() {
    this.lang = this.sessionData.getJsonLanguages(this.sessionData.currentlanguage);
  }

  ionViewDidEnter() {
    this.registerActionButton();
  }

  ionViewWillLeave() {
    this.sessionData.setData('providerslist', [...this.providersUnmute]);
    this.backButton.deregisterAction();
  }

  async ionViewWillEnter() {
    this.isLoading = true;
    try {
      const time: any = await this.sessionData.getData('lastTimeProviderList');
      if (time) {
        const validated = this.timeValidate.isLessThanXMins(new Date(), new Date(time), 1);
        validated ? this.getBackendProviders() : this.getLocalProviders();
      } else {
        this.getBackendProviders();
      }
    } catch (error) {
      this.getBackendProviders();
    }
  }

  setFilteredItems() {
    const shouldLookId = isNaN(this.searchTerm);
    if (shouldLookId) {
      this.providers = this.filterList.filterItemsProvider(this.searchTerm, this.providersUnmute);
    }
    else {
      this.providers = this.filterList.filterItems(this.searchTerm, 'identification', this.providersUnmute);
    }

    this.hasItemsFilterList = this.providers.length > 0;

  }

  openProfile(provider, index) {
    if (this.from === 1) {
      this.modalCtrl.dismiss({
        provider
      });
    } else {
      this.active(provider, false, index);
    }
  }

  async active(provider = null, from = true, index = -1) {
    const modal = await this.modalCtrl.create({
      component: NewProviderPage,
      cssClass: 'normal-modal',
      componentProps: {
        from,
        provider
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data && data.provider) {
      const provider: Provider = data.provider;
      const getCrudData = this.callbackService.getCrudObject();
      this.callbackService.handleEventCallback(data.action, {
        [getCrudData.ADD]: this.addProvider.bind(this, provider),
        [getCrudData.EDIT]: this.editProvider.bind(this, provider, index),
        [getCrudData.DELETE]: this.deleteProvider.bind(this, index)
      })
    }
  }

  addProvider(provider) {
    this.providers.push(provider);
    this.providersUnmute.push(provider);
    this.hasItems = this.providersUnmute.length > 0;
    this.sessionData.setData('providerslist', [...this.providersUnmute]);
  }

  editProvider(provider, index) {
    this.providers[index] = { ...provider };
    this.providersUnmute[index] = { ...provider };
    this.hasItems = this.providersUnmute.length > 0;
    this.sessionData.setData('providerslist', [...this.providersUnmute]);
  }

  deleteProvider(index) {
    this.providers.splice(index, 1);
    this.providersUnmute.splice(index, 1);
    this.hasItems = this.providersUnmute.length > 0;
    this.sessionData.setData('providerslist', [...this.providersUnmute]);
  }


  async getBackendProviders() {
    const query = getProviders();
    try {
      const response = await this.connection.post(query, true);
      this.isLoading = false;
      console.log(response);
      if (response.data) {
        const { getUserProviders }: any = response.data;
        const { success, data, message }: GetUserProvidersOutput = getUserProviders;
        if (success && data) {
          const list: Array<Provider> = data;
          this.providers = [...list];
          this.providersUnmute = [...list];
          this.hasItems = this.providersUnmute.length > 0;
          this.sessionData.setData('providerslist', [...this.providersUnmute]);
          this.sessionData.setData('lastTimeProviderList', Date.now());
        } else {
          this.alertService.presentToast(message);
        }
      } else {
        this.alertService.presentToast(this.lang.ERRORS.PROVIDERSGET); //TODO response.errors
      }
    } catch (e) {
      this.isLoading = false;
      this.alertService.presentToast(this.lang.ERRORS.PROVIDERSGET);
    }
  }

  async getLocalProviders() {
    this.isLoading = false;
    try {
      const list: any = await this.sessionData.getData('providerslist');
      if (list) {
        this.providers = [...list];
        this.providersUnmute = [...list];
        this.hasItems = this.providersUnmute.length > 0;
      } else {
        this.providers = [];
        this.providersUnmute = [];
        this.hasItems = false;
      }
    } catch (e) {
      this.providers = [];
      this.providersUnmute = [];
      this.hasItems = false;
    }
  }


  registerActionButton() {
    this.backButton.registerAction(() => {
      this.backButton.goBack().then(
        () => (this.from === 1) ? this.redirectOnboard() : this.backButton.goBackLocation(this.location),
        error => console.log(error)
      );
    }, 100);
  }

  redirectOnboard(provider = null) {
    if (this.from === 1) {
      this.modalCtrl.dismiss({
        provider
      });
    } else {
      this.router.navigate(['home-dash-board']);
    }
  }


}
