import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ChoosePicService } from 'src/providers/choose-pic/choose-pic.service';
import { LoadingService } from 'src/providers/loading/loading.service';
import { SessiondataService } from 'src/providers/sessiondata/sessiondata.service';
import { patterns } from 'src/constants/pattern';
import { countries } from 'src/constants/countries';
import { AlertService } from 'src/providers/alert/alert.service';
import { AndroidPermissionsService } from 'src/providers/android-permissions/android-permissions.service';
import { GraphqlConnectionService } from 'src/providers/graphql-connection/graphql-connection.service';
import { AEOutput } from 'src/constants/connections/user/id';
import { getSimpleID } from 'src/constants/connections/register/queries';
import { AddClientInput, AddClientOutput, Client } from 'src/constants/connections/client/client';
import { addClient, deleteClient, updateClient } from 'src/constants/connections/client/queries';
import { PlacesService } from 'src/providers/places/places.service';
import { generate } from 'shortid';
import { ExonetLineComponent } from 'src/components/exonet-line/exonet-line.component';
import { Canton, District, GetCantonsInput, GetDistrictsInput, GetNeighborhoodsInput, Neighborhood, Province } from 'src/constants/connections/places/places';
import { InputValidators } from 'src/providers/validators/inputvalidators';
import { AndroidBackButtomService } from 'src/providers/navigation/android-back-button';

@Component({
  selector: 'app-new-client',
  templateUrl: './new-client.page.html',
  styleUrls: ['./new-client.page.scss'],
})
export class NewClientPage implements OnInit {

  clickedImage: any ="";
  lang: any;
  @ViewChild('mainC', { read: ViewContainerRef }) entry: ViewContainerRef;
  client_type : FormControl; //TODO BORRAR ESTO 
  client_name : FormControl;
  client_lastname : FormControl;
  client_email : FormControl;
  client_phone : FormControl;
  client_whatsapp : FormControl;
  client_country : FormControl;
  client_city : FormControl;
  client_address : FormControl;
  client_id: FormControl;
  client_canton: FormControl;
  client_district: FormControl;
  client_neighborhood: FormControl;

  formClient;
  arrayOfExonet= [];
  exonerations = [{docNumber:"ererereerre"}]
  
  buttons = [ ];

  selectedState:any = '';
  selectedCity:any = '';
  selectedTown:any = '';
  selectedNeighborhood:any = '';
  selectedID: any= ""
  selectedClientType: any= ""



  hasErrorCountry:boolean = false;
  hasErrorCity:boolean = false;
  hasErrorID:boolean = false;
  hasErrorClientType:boolean = false;
  hasErrorTown:boolean = false;
  hasErrorCAnt:boolean = false;
  hasErrorNeigh:boolean = false;

  @Input() from: boolean;
  @Input() client: Client;

  typeOfId = '01';
  component = ExonetLineComponent;
  provinces: any = [];
  cantones: any = [];
  distrits: any = [];
  neighborhoods: any = [];
  user;
  fullCountry;
  isLoading : Boolean = false;
  hasErrorState: boolean;
  selectedCant: any;

  constructor(
    public resolver: ComponentFactoryResolver,
    private choosePic : ChoosePicService,
    private sessiondata: SessiondataService,
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    private permission: AndroidPermissionsService,
    private loadingController: LoadingService,
    private connection: GraphqlConnectionService,
    private places: PlacesService,
    private alertService: AlertService,
    private backButton: AndroidBackButtomService) { }

  ngOnInit() {
    this.lang = this.sessiondata.getJsonLanguages(this.sessiondata.currentlanguage);
    this.getDataFromLocal();
    this.clientForm();
    this.buttons = [
      {
        text: this.lang.BUTTONS.BTNCANCEL,
        role: 'cancel',
        cssClass: 'btnModalDismiss'
      }, {
        text: this.lang.BUTTONS.BTNACCEPT,
        cssClass: 'btnModalAcept'
      }
    ];

    
  }

  ionViewDidEnter() { 
    this.registerActionButton(); 
  }

  ionViewWillLeave() { 
    this.backButton.deregisterAction(); 
  }

  registerActionButton(){
    this.backButton.registerAction(()=>{
      this.backButton.goBack().then(
        () => this.redirectCLientPage(),
        error => console.log(error)
      );
    }, 100);
  }

  async getDataFromLocal(){
    try{
      this.user = this.sessiondata.getUser()
      //const provs = await this.places.getCRCProvinces();
      //this.provinces = this.places.createRadioPlaces(provs,'province','key','code');
      await this.requestProvinces();
      if(!this.user){
        let localUser;
        localUser = await this.sessiondata.getData('registerUser');
        if(!localUser)
          localUser = await this.sessiondata.getData('isLoggedIn');
        this.user = localUser;
      }
      if(!this.from){
        this.selectedState = await this.provinces.find(p => p.code == this.client.address.province.code);
        const province: GetCantonsInput = {
          idProvince: this.selectedState.code
        }
        await this.requestCantones(province);
        this.selectedCity = this.cantones.find(p => p.code == this.client.address.canton.code);
        const canton: GetDistrictsInput = {
          idCanton: this.selectedCity.key
        }
        await this.requestDistricts(canton);
        this.selectedTown = this.distrits.find(p => p.code == this.client.address.district.code);
        const district: GetNeighborhoodsInput = {
          idDistrict: this.selectedTown.key
        }
        await this.requestNeighborhoods(district);
        this.selectedNeighborhood = this.neighborhoods.find(p => p.code == this.client.address.neighborhood.code);
        if(this.client.exonet)
          if(this.client.exonet.length>0){
            this.client.exonet.forEach(exoneration =>{
              //this.addEmptyExonet(exoneration.docNumber)
            })
          }
      }

    }catch(e){
      console.log(e);
    }
  }

  async choosePicture(){
    try{
      const wantPic = await this.choosePic.chooseTypeAndTakePhoto(this.lang);
      console.log(wantPic)
      if(wantPic){
        try{
          const permission = await this.permission.hasPermissions(this.permission.permission2s());
          if(permission){
            const image = await this.choosePic.takephoto(wantPic);
            if(image){
              this.clickedImage = image;
            }
          }else{
            const askPermission = await this.permission.requestPermissions(this.permission.permission2s());
            if(askPermission){
              const image = await this.choosePic.takephoto(wantPic);
              if(image){
                this.clickedImage = image;
              }
            }else{
              console.log('error')
            }
          }
        }catch(e){
          console.log(e)
        }
      }
    }catch(e){
      console.log(e);
    }
  }

  clientForm(){

    const {
      landLinePhone,
      movilePhone,
      email,
      address,
      name,
      lastName,
      identification,
      identificationType,
      businessName,
      tradeName,
      profilePicture,
      clientType,
      exonet
    } = this.client || {};
    const currentName = identificationType === "PERSON" ? name: businessName;
    const currentLastName = identificationType === "PERSON"  ? lastName: tradeName;

    if(this.client){
      //this.selectedCountry = address.country;
      //this.fullCountry = countries.find(country =>  country.value == this.selectedCountry );
      this.typeOfId = identificationType;
    }
    
    this.client_name = new FormControl( currentName || '', [Validators.required]);
    this.client_lastname = new FormControl( currentLastName ||'', [Validators.required]);
    this.client_email = new FormControl( email ||'', [
      Validators.required,
      Validators.pattern(patterns.email)
    ]);

    this.client_phone = new FormControl(landLinePhone && landLinePhone.nationalNumber ||'', [
      InputValidators.phoneNumerValidator
    ]);

    this.client_whatsapp = new FormControl(movilePhone && movilePhone.nationalNumber ||'', [
      InputValidators.phoneNumerValidator
    ]);


    this.client_city = new FormControl(address && address.province.name || '', [
      Validators.required
    ]);

    this.client_address = new FormControl(address && address.exactAddress ||'', [
      Validators.required
    ]);

    this.client_id = new FormControl( identification || '', [
      Validators.required,
      InputValidators.identityValidator
    ]);

    this.client_canton = new FormControl(address && address.canton.name ||'', [
      Validators.required
    ]);

    this.client_district = new FormControl(address && address.district.name || '', [
      Validators.required
    ]);

    this.client_neighborhood = new FormControl(address && address.neighborhood.name || '', [
      Validators.required
    ]);

 
    this.clickedImage = profilePicture || '';

    this.formClient = this.formBuilder.group({
      client_name: this.client_name,
      client_lastname: this.client_lastname,
      client_email: this.client_email,
      client_phone: this.client_phone,
      client_whatsapp: this.client_whatsapp,
      client_city: this.client_city,
      client_canton: this.client_canton,
      client_district: this.client_district,
      client_neighborhood: this.client_neighborhood,
      client_address: this.client_address,
      client_id: this.client_id,
    });

    if(exonet && exonet.length > 0){
      const arrEx = exonet.map(ex => { return { 
        text: ex.docNumber,
        isValid: true
      }})

      setTimeout(()=>{
        arrEx.forEach(ex=>{
          this.addEmptyExonet(ex.text,ex.isValid);
        })
      },200)
    }
  }

  async stateAlert() {
    try {
      const response: any = await this.alertService.showListAlert(this.lang.ALERT.CITY, this.buttons, 'customAlert', this.provinces, this.getType('client_city'));
      if (response) {
        const fullState = this.provinces.find(e => e.value.includes(response))
        this.selectedState = fullState
        console.log("selected "+JSON.stringify(this.selectedState))
        this.setType('client_city', fullState.label)
        const province: GetCantonsInput = {
          idProvince: fullState.key
        }
        this.requestCantones(province);
        this.cleanSelects('byProvince');
        this.hasErrorState = false;
      }
      else {
        this.hasErrorState = this.getType('client_canton') === '';
      }
    } catch (e) {
      this.hasErrorState = this.getType('client_canton') === '';
    }
  }

  async cityAlert() {
    try {
      const response: any = await this.alertService.showListAlert(this.lang.ALERT.CITY2, this.buttons, 'customAlert', this.cantones, this.selectedCity);
      if (response) {
        const fullCity = this.cantones.find(e => e.value.includes(response))
        this.selectedCity = fullCity
        this.setType('client_canton', fullCity.label)
        const canton: GetDistrictsInput = {
          idCanton: fullCity.key
        }
        this.requestDistricts(canton);
        this.cleanSelects('byCanton');
        this.hasErrorCity = false;
      }
      else {
        this.hasErrorCity = this.selectedCity === '';
      }
    } catch (e) {
      this.hasErrorCity = this.selectedCity === '';
    }
  }

  async townAlert() {
    try {
      const response: any = await this.alertService.showListAlert(this.lang.ALERT.CITY3, this.buttons, 'customAlert', this.distrits, this.selectedTown);
      if (response) {
        const fullTown = this.distrits.find(e => e.value.includes(response))
        this.selectedTown = fullTown
        this.setType('client_district', fullTown.label);
        const district: GetNeighborhoodsInput = {
          idDistrict: fullTown.key
        }
        this.requestNeighborhoods(district);
        this.cleanSelects('byDistrito');
        this.hasErrorTown = false;
      } else {
        this.hasErrorTown = this.selectedTown === '';
      }
    } catch (e) {
      this.hasErrorTown = this.selectedTown === '';
    }
  }

  async neighborhoodAlert() {
    try{
      const response: any = await this.alertService.showListAlert( this.lang.ALERT.CITY3, this.buttons,'customAlert',this.neighborhoods, this.selectedNeighborhood);
      if(response){
        const fullNeighbor = this.neighborhoods.find(e => e.value.includes(response))
        this.selectedNeighborhood= fullNeighbor
        this.setType('client_neighborhood',fullNeighbor.label);
        this.hasErrorNeigh = false;
      }else{
        this.hasErrorNeigh = this.selectedNeighborhood === null;
      }
    }catch(e){
      this.hasErrorNeigh = this.selectedNeighborhood === null;
    }
  }

  

  getType(type){
    return this.formClient.controls[type].value;
  }

  setType(type,value){
    this.formClient.controls[type].setValue(value);
  }

  redirectCLientPage(client = null, action = 'add'){
    this.modalCtrl.dismiss({
      client,
      action
    })
  }

  async goToSave(){
    this.isLoading = true;
    const client = this.getCLient();
    const { ERRORS } = this.lang;
    try {
      const mutation = this.from ? addClient(client) : updateClient(client) ;
      const response = await this.connection.post(mutation, true);
      if(response && response.data){
        const { addClient, updateClient } : any = response.data;
        const { success, data, message, code, description } : AddClientOutput = this.from ? addClient: updateClient;
        if(success && data){
          if(!code){
            this.alertService.presentToast(description, 'warning', 4000);
            setTimeout(()=> this.alertService.presentToast(message, 'primary', 2000), 5000)
          }else{
            this.alertService.presentToast(message, 'primary');
          }
          const savedClient : Client = data;
          this.redirectCLientPage(savedClient, this.from ? 'add' : 'edit');
        }else{
          this.alertService.presentToast(message, 'warning');
        }
      }else{
        this.alertService.presentToast( this.from ? ERRORS.ADDCLIENT : ERRORS.UPDATECLIENT);
      }
      this.isLoading = false;
    }catch(e){
      this.alertService.presentToast( this.from ? ERRORS.ADDCLIENT: ERRORS.UPDATECLIENT);
      this.isLoading = false;
    }
  }

  async goToDelete(){
    this.isLoading = true;
    const { ERRORS } = this.lang;
    try {
      const client = {
        clientID: this.client._id
      }
      const mutation = deleteClient(client);
      console.log(mutation);
      const response = await this.connection.post(mutation, true);
      this.isLoading = false;
      if(response && response.data){
        const { deleteClient } : any = response.data;
        const { success, message } : AddClientOutput = deleteClient;
        if(success){
          this.alertService.presentToast(message, 'primary');
          this.redirectCLientPage(this.client, 'delete');
        }else{
          this.alertService.presentToast(message);
        }
      }else{
        this.alertService.presentToast(ERRORS.DELETINGCLIENT);
      }
    }catch(e){
      this.alertService.presentToast(ERRORS.DELETINGCLIENT);
      this.isLoading = false;
    }
  }

  getMessageError(value,type){

    const forms =  this.lang.FORMS;

    if(!!value.errors){
      const { required, pattern } = value.errors
      if(required){
        return forms.REQUIRED;
      }else if(!!pattern){

        if(type == 'email'){
          return forms.EMAIL;
        }

        if(type == 'phone'){
          return forms.PHONE;
        }

        if(type == 'number'){
          return forms.ID;
        }

        return forms.REQUIRED;
      }else if(value.errors?.invalidPhone){
        return forms.PHONE;
      } else if(value.errors?.identityPhone){
        return forms.ID;
      }
    }
  }

  getCLient() {

      let clientByType: any = {}
      if(this.typeOfId === '01'){
        clientByType = {
          name: this.getType('client_name'),
          lastName: this.getType('client_lastname'),
          businessName: this.getType('client_name'),
          tradeName: this.getType('client_name'),
        }
      }else{
        clientByType = {
          name: this.getType('client_name'),
          lastName: this.getType('client_lastname'),
          businessName: this.getType('client_name'),
          tradeName: this.getType('client_lastname'),
        }
      }

      const address = {
        exactAddress: this.getType('client_address'),
        province: {
          code:this.selectedState.code,
          name:this.selectedState.label
        },
        canton:  {
          code:this.selectedCity.code,
          name:this.selectedCity.label
        },
        district:  {
          code:this.selectedTown.code,
          name: this.selectedTown.label
        },
        neighborhood:  {
          code:this.selectedNeighborhood.code,
          name:this.selectedNeighborhood.label
        }
      }

      const landLinePhone ={
        e164: `+${"506"}${this.getType('client_phone')}`,
        nationalNumber: this.getType('client_phone'),
        countryCode: "506"
      }

      const movilePhone ={
        e164: `+${"506"}${this.getType('client_whatsapp')}`,
        nationalNumber: this.getType('client_whatsapp'),
        countryCode: "506"
      }

      const exonet = this.returnValidExonets();


      const client : AddClientInput = {
        address,
        movilePhone,
        email: this.getType('client_email'),
        landLinePhone,
        ...clientByType,
        clientType:  this.typeOfId === "01" ? "person" : "company",
        identification: this.getType('client_id'),
        identificationType: this.typeOfId,
        profilePicture: this.clickedImage,
        exonet: exonet
      }

      return client;
  }

  async findIds(){
    const id = this.getType('client_id');
    if(this.user && this.user.identification.includes(id)){
      this.alertService.presentToast(this.lang.OWNERPROFILE.ERROR_ID_3);
      this.setType('client_id','')
    }else{
      this.loadingController.loadingPresent(this.lang.OWNERPROFILE.LOADING);
      const query = getSimpleID(id);
      try{
        const response = await this.connection.post(query);
        if(response.data){
          this.loadingController.loadingDismiss();
          const { getBillingUserStatus } : any = response.data;
          const { success, data } : AEOutput = getBillingUserStatus;
          if(success && data){
            this.manageData(data);
          }else{
            this.alertService.presentToast(this.lang.OWNERPROFILE.ERROR_ID_2);
          }
        }else{
          this.loadingController.loadingDismiss();
          if(response.errors){
            console.log(response.errors)
          }else{
            console.log(response.errors)
            this.alertService.presentToast(this.lang.OWNERPROFILE.ERROR_ID_2);
          }
        }
      }catch(e){
        this.alertService.presentToast(this.lang.OWNERPROFILE.ERROR_ID_2);
        this.loadingController.loadingDismiss();
      }
    }
  }

  manageData(user){
    this.setType('client_lastname', (user.tipoIdentificacion === '01' || user.tipoIdentificacion === '03') ? user.nombre : '');
    this.setType('client_name',user.nombre);
    console.log("tipo is"+user.tipoIdentificacion)
    this.typeOfId = user.tipoIdentificacion;
  }


  //exonet 

  addEmptyExonet(textC: any='',isValid=false){
    this.arrayOfExonet.push({
      text: textC ,
      id: generate(),
      isValid
    })

    const current = this.arrayOfExonet[this.arrayOfExonet.length - 1];
    const {id,text} = current;
    
    this.singleExonet(id,text);

    this.from && this.scrollToBottom(id);
  }

  singleExonet(id,text){
    const factory = this.resolver.resolveComponentFactory(this.component as any);
    const ref : any = this.entry.createComponent(factory);
    ref.instance.id = id || generate();
    ref.instance.text = text || '';
    ref.instance.exonetArray = this.arrayOfExonet;

    ref.instance.inputValue.subscribe(({value,id,isValid}) => {
      const pos = this.arrayOfExonet.findIndex( agencyCurrent => id == agencyCurrent.id ); 
      if(pos > -1){
        this.arrayOfExonet[pos] = {
          ...this.arrayOfExonet[pos],
          text: value,
          isValid
        }
        ref.instance.exonetArray = this.arrayOfExonet;
      }
    });

    ref.instance.DeleteRow.subscribe(id => {
      this.removeThisElement(id,ref);
    });
  }

  removeThisElement(id,ref){
    const pos = this.arrayOfExonet.findIndex( agencyCurrent => { return  id == agencyCurrent.id } ); 
    if(pos > -1){
      this.entry.remove(pos);
      this.arrayOfExonet.splice(pos, 1);
      ref.instance.currentArray = this.arrayOfExonet;
    }
  }

  scrollToBottom (id) {
    setTimeout(()=>{
      const currentView = document.getElementById(id);
      currentView.scrollIntoView();
    },100)
  }

  returnValidExonets(){
    return this.arrayOfExonet.filter(exonet => exonet.isValid);
  }

  /*
  async requestProvinces(key) {
    switch (key) {
      case "Costa Rica":
        const province : Province = await this.places.getCRCProvinces();
        this.provinces = this.places.createRadioPlaces(province,'province','key','code');
        break;
      default:
        break;
    }
  }
  */


  async requestProvinces() {
    const province : Province = await this.places.getCRCProvinces();
    this.provinces = this.places.createRadioPlaces(province,'province','id','code');
  }

  async requestCantones(province: GetCantonsInput) {
    const tempCantones: Array<Canton> = await this.places.getCantonService(province);
    this.cantones =  this.places.createRadioPlaces(tempCantones,'canton','id','code');
  }

  async requestDistricts(canton: GetDistrictsInput) {
    const tempDistricts: Array<District> = await this.places.getDistrictService(canton);
    this.distrits =  this.places.createRadioPlaces(tempDistricts,'district','id','code');
  }

  async requestNeighborhoods(district: GetNeighborhoodsInput) {
    const tempNeighborhoods: Array<Neighborhood> = await this.places.getNeighborhoodsService(district);
    this.neighborhoods =  this.places.createRadioPlaces(tempNeighborhoods,'neighborhood','id','code');
  }

  cleanSelects(key) {
    switch (key) {
      case "byCountry":
        this.client_city.setValue("");
        this.client_canton.setValue("");
        this.client_district.setValue("");
        this.selectedCant = null;
        this.selectedCity = null;
        this.selectedTown = null;
        this.provinces = [];
        this.cantones = [];
        this.distrits = [];
        this.hasErrorCAnt = false;
        this.hasErrorCity = false;
        this.hasErrorTown = false;
        break;
      case "byProvince":
        this.client_canton.setValue("");
        this.client_district.setValue("");
        this.selectedCant = null;
        this.selectedCity = null;
        this.selectedTown = null;
        this.cantones = [];
        this.distrits = [];
        this.hasErrorCAnt = false;
        this.hasErrorTown = false;
        break;
      case "byCanton":
        this.client_district.setValue("");
        this.selectedTown = null;
        this.distrits = [];
        this.hasErrorTown = false;
        break;
    }
  }
}
