import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button-rounded',
  templateUrl: './button-rounded.component.html',
  styleUrls: ['./button-rounded.component.scss'],
})
export class ButtonRoundedComponent implements OnInit {

  @Input('icon') icon: string;
  @Input('buttonClassName') buttonClassName: string;
  @Input() hasNotification: boolean;
  @Output() ButtonClick = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  valueChanged(event){
    this.ButtonClick.emit(event);
  }

}
