import { FindCabysServiceInput } from './cabys'


export function fetchCabysCodes(data: FindCabysServiceInput){
    const { name, code, top } = data;
    return `query {
        getCabysServices(FindCabysServiceInputData:{
            name:"${name}"
            code:"${code}"
            top:"${top}"
            }){
                success
                message
                description
                code
                data{
                    descripcion
                    codigo
                    impuesto
                    uri
                    categorias
                }
            }
        }`
}
