export function getTaxRates(){
    return `
        query{
            getTaxRates
            {
                success
                message
                description
                data{
                    name
                    code
                }
            }
        }
    `
}

export function getDollarCurrencyBackend(){
    return `
    {
        getDollarCurrencyExchange{
          success
          message
          description
          data{
            venta{
              fecha
              valor
            }
            compra{
              fecha
              valor
            }
          }
        }
          getEuroCurrencyExchange{
          success
          message
          description
          data{
                  colones
            dolares
            fecha
          }
        }
      }
    `
}