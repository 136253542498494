import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventCallbackService {

  constructor() { }

  getCrudObject(){
    return {
      ADD: 'add',
      DELETE: 'delete',
      EDIT: 'edit'
    }
  }

  getNumberObject(){
    return {
      ONE: 1,
      TWO: 2,
      THREE: 3,
      FOUR: 4,
      FIVE: 5,
    }
  }

  handleEventCallback = (key, callbacks) => {
      if (callbacks && callbacks[key]) {
          callbacks[key]();
      }
  };
}