import { Component, Input, OnInit, } from '@angular/core';

@Component({
  selector: 'app-skeleton-list',
  templateUrl: './skeleton-list.component.html',
  styleUrls: ['./skeleton-list.component.scss'],
})
export class SkeletonListComponent implements OnInit {
  a = 0;
  numbers: Array<number> = [];
  @Input('hasHeader') hasHeader: boolean;
  @Input('numberOfItems') numberOfItems: any;

  shouldShowHeader: boolean = false;

  constructor() { 

    
  }

  ngOnInit() {
      this.shouldShowHeader = this.hasHeader;
      const number = parseInt(this.numberOfItems);
      this.numbers = Array(number).fill(4);
  }

}
