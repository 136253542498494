import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import { SessiondataService } from '../../providers/sessiondata/sessiondata.service';
import { async } from 'rxjs/internal/scheduler/async';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ConnectionManagerService {
  headers: Headers;
  typePolling: any = 'default';

  constructor(private commonhttp: HttpClient, 
    private nativeHttp: HTTP, 
    private sessiondata: SessiondataService,
    private platform: Platform) { 

  }

  getData(URL) {
    return this.commonhttp.get(URL);
  }

  postData(URL, body) {
    return this.commonhttp.post(URL, body);
  }

  getNative(url: string, data: any, headers) {
    this.setHeadersNative(headers);
    return new Promise(async (resolve, reject) => {
      try {
        if(this.platform.is('ios')){
          debugger;
          const response = await this.nativeHttp.get(url, data, headers);
          resolve(response.data);
        }else{
          const response = await this.nativeHttp.get(url, data, headers);
            resolve(response.data);
        }
        //   const token: any = await this.httpNative.setSSLCertMode(this.typePolling);
        //   debugger;
        //   if(token === 'OK'){
        //     const response = await this.httpNative.get(url, data, headers);
        //     resolve(response.data);
        //   }else
        //     reject(null)
        // }
      } catch (error) {
        reject(error);
      }
    })
  }

  postNative(url,data,headers){
    this.setHeadersNative(headers);
    return new Promise(async (resolve,reject)=>{
      try{
        if(this.platform.is('ios')){
          const response = await this.nativeHttp.post(url, data, headers);
          resolve(response.data);
        }else{
          const response = await this.nativeHttp.post(url, data, headers);
          resolve(response.data);
        }
        // const token : any = await this.nativeHttp.setServerTrustMode(this.typePolling);
        // if(token === 'OK'){
        //   const response = await this.nativeHttp.post(url, data, headers);
        //   resolve(response.data);
        // }else
        //   reject(null)
        // }
      }catch(e){
        // e.status == '403' && this.session.showAlertObserver("<h5 class='errorTitle'> "+  this.session.languageMessage.global.passer_error_title + "</h5>","<p class='blackText font-normal'>" + this.session.languageMessage.global.passer_error_message2 +"</p>" ,"",this.session.languageMessage.actions.agreed,"").subscribe(
        //   data=>{
        //     this.session.val.setRoot("WelcomePage",{},{animate: true, animation: "slideTransition" , direction : "back"}); 
        //     this.removeLogin();
        //   }
        // )
        e.status != '403' && reject(e.error);
      }
    });
  }

  putNative(url : string, data : any, headers){
    this.setHeadersNative(headers);
    return new Promise(async (resolve,reject)=>{
      try{
        if(this.platform.is('ios')){
          const response = await this.nativeHttp.put(url, data, headers);
          resolve(response.data);
        }else{
          const response = await this.nativeHttp.put(url, data, headers);
          resolve(response.data);
        }
        //   const token: any = await this.nativeHttp.setServerTrustMode(this.typePolling);
        //   if(token === 'OK'){
        //   const response = await this.nativeHttp.put(url, data, headers);
        //   resolve(response.data);
        //   }
        //   else
        //     reject(null)
        // }
      }catch(e){
        // e.status == '403' && this.session.showAlertObserver("<h5 class='errorTitle'> "+  this.session.languageMessage.global.passer_error_title + "</h5>","<p class='blackText font-normal'>" + this.session.languageMessage.global.passer_error_message2 +"</p>" ,"",this.session.languageMessage.actions.agreed,"").subscribe(
        //   data=>{
        //     this.session.val.setRoot("WelcomePage",{},{animate: true, animation: "slideTransition" , direction : "back"}); 
        //     this.removeLogin();
        //   }
        // )
        e.status != '403' && reject(e.error);
      }
    })
  }

  private extractData(res: Response) {
    console.log('el response ', res);
    let body = res.json();
    return body || {};
  }
  private handleError(error: Response | any) {
    // let errMsg: string;
    if (error instanceof Response) {
      // const body = error.json() || '';
      // const err = body.error || JSON.stringify(body);
      // errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      // errMsg = error.message ? error.message : error.toString();
    }
    return Observable.throw(error.json());
  }

  setHeadersNative(headers) {
    console.log(headers)
    this.nativeHttp.setDataSerializer('json');
    this.nativeHttp.setRequestTimeout(60000)
    this.nativeHttp.setHeader('*', 'Accept-Language', this.sessiondata.currentlanguage);
    this.nativeHttp.setHeader('*', 'Content-Type', 'application/json');
  }
}
