import { PreRegisterUserInput } from 'src/constants/connections/register/register';
import { CreateUserInput } from '../user/create';
import { User } from '../user/user';

export function preRegisterUser(e164,nationalNumber,countryCode){
    return `mutation {
                preRegisterUser(
                    preRegisterUserData: {
                        e164: "${e164}"
                        nationalNumber: "${nationalNumber}"
                        countryCode: "${countryCode}"
                    }
                    )
                        { 
                            success
                            message
                            description
                            code
                        }
                }
    `
}

export function validateAccount(number : PreRegisterUserInput, code){
    const { e164,nationalNumber,countryCode } = number;
    return `mutation {
        validateAccount(
            validateAccountInput: {
                phone: {
                    e164: "${e164}"
                    nationalNumber: "${nationalNumber}"
                    countryCode: "${countryCode}"
                }
                code: "${code}"
                
            }
            )
                { 
                    success
                    message
                    description
                    code
                }
        }
    `
}

export function getSimpleID(id){
    console.log(id)
    return `
    query {
        getBillingUserStatus(getBillingUserStatusData : { identification: "${id}"}){
            success
            message
            description
            code
            data{
                nombre
                tipoIdentificacion
                regimen{
                    codigo
                    descripcion
                }
                situacion{
                    moroso
                    omiso
                    estado
                    administracionTributaria
                }
                actividades {
                    estado
                    tipo
                    codigo
                    descripcion
                }
                }
            } 
        }
    `
}

export function createUser(user: CreateUserInput){

    const {
        name,
        lastName,
        password,
        identification,
        identificationType,
        email,
        dteBirthday,
        phone,
        useDataOnATV,
        profileIsCompleted,
        profilePicture,
        gender
    } = user;

    return `mutation {
        createUser(createUserData : {
            fullName: "${name}"
            lang: "esp"
            name: "${name}"
            lastName: "${lastName}"
            password: "${password}"
            identification: "${identification}"
            identificationType: "${identificationType}"
            email: "${email}"
            dteBirthday: "${dteBirthday}"
            phone: {
                e164: "${phone.e164}"
                nationalNumber: "${phone.nationalNumber}"
                countryCode: "${phone.countryCode}"
            }
            useDataOnATV: ${useDataOnATV},
            profilePicture: "${profilePicture}"
            gender: "${gender}"
        }){
            success
            message
            code
            data{
                fullName
                  name
                  lastName
                  identification
                  dteBirthday
                  identificationType
                  email
                  phone{
                      e164
                      nationalNumber
                      countryCode
                  }
                  useDataOnATV
                  termsAccepted
                  profilePicture
                  gender
                  }
                token
            }
        }`
}
