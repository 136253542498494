import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-infinte-loader',
  templateUrl: './infinte-loader.component.html',
  styleUrls: ['./infinte-loader.component.scss'],
})
export class InfinteLoaderComponent implements OnInit {

  @Input('isLoading') isLoading: boolean;
  @Input('type') type: string;

  @ViewChild('loader') loader; 

  heightHeader: any = '0';

  constructor(private changDetector: ChangeDetectorRef) { }

  ngOnInit() {
    
  }

  ngOnChanges(){
    setTimeout(()=>{
      this.changDetector.detectChanges();
      const iosHeader = document.querySelector('.toolbar-title-default');
      if(iosHeader){
        this.heightHeader = iosHeader && iosHeader.clientHeight;
        
      }else{
        const headerMd = document.querySelector('.in-toolbar');
        console.log("headerMd")
        console.log(headerMd.clientHeight)
        this.heightHeader = headerMd && headerMd.clientHeight;
      }

      if(this.loader && this.loader.nativeElement)
          this.loader.nativeElement.style.paddingTop = `${this.heightHeader}px`;
    
    },100)
  }
}
