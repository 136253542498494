import { AddClientInput, DeleteClientInput } from "./client"

function returnedExo(stock){
    let myExonet = ''
    stock.forEach(exonet=>{
        myExonet += `{
            docNumber: "${exonet.text}"
        }`
    });
    return myExonet;
}

export function addClient(client : AddClientInput){

    const {
        clientType,
        landLinePhone,
        movilePhone,
        email,
        identification,
        identificationType,
        address,
        name,
        lastName,
        businessName,
        tradeName,
        profilePicture,
        exonet
    } = client;

    const currentExon = returnedExo(exonet)

    return `mutation
    {
        addClient(
            addClientData : {
                clientType : "${clientType}"
                landLinePhone: {
                    e164:  "${landLinePhone.e164}"
                    nationalNumber:  "${landLinePhone.nationalNumber}"
                    countryCode:  "${landLinePhone.countryCode}"
                } 
                movilePhone: {
                    e164:  "${movilePhone.e164}"
                    nationalNumber:  "${movilePhone.nationalNumber}"
                    countryCode:  "${movilePhone.countryCode}"
                }
                email : "${email}"
                identification: "${identification}"
                identificationType: "${identificationType}"
                address: {
                    province: {
                        code: "${address.province.code}"
                        name: "${address.province.name}"
                    }
                    canton:  {
                        code: "${address.canton.code}"
                        name: "${address.canton.name}"
                    }
                    district:  {
                        code: "${address.district.code}"
                        name: "${address.district.name}"
                    }
                    neighborhood:  {
                        code: "${address.neighborhood.code}"
                        name: "${address.neighborhood.name}"
                    }
                    exactAddress: "${address.exactAddress}"
                }
                name : "${name.substring(0,100)}"
                lastName : "${name.substring(0,80)}"
                businessName : "${name.substring(0,80)}"
                tradeName : "${tradeName.substring(0,80)}"
                profilePicture : "${profilePicture}"
                ${exonet.length > 0 ? `exonet:[${currentExon}]`: ''}
            }
                ){
            success
            message
            description
            code
            data{
                _id
                IDUser
                landLinePhone{
                    e164
                    nationalNumber
                    countryCode
                }
                movilePhone{
                    e164
                    nationalNumber
                    countryCode
                }
                email
                clientType
                address{
                    province{
                      code
                      name
                    }
                   canton{
                      code
                      name
                    }
                    district{
                      code
                      name
                    }
                    district{
                      code
                      name                      
                    }
                  neighborhood{
                  	code
                    name
                  }
                    exactAddress
                }
                    name
                    lastName
                    identificationType
                    identification
                    businessName
                    tradeName
                    societyType
                    profilePicture
                    exonet {
                        docNumber
                        exonerationPercentage
                        expireDate
                        cabysCodes
                        emisionDate
                    }
            }
        }
        
    }`

}

export function updateClient(client : AddClientInput){
    const {
        clientType,
        landLinePhone,
        movilePhone,
        email,
        identification,
        identificationType,
        address,
        name,
        lastName,
        businessName,
        tradeName,
        profilePicture,
        exonet
    } = client;

    const currentExon = returnedExo(exonet)

    return `mutation{
        updateClient(
            updateClientData : {
                clientType : "${clientType}"
                landLinePhone: {
                    e164:  "${landLinePhone.e164}"
                    nationalNumber:  "${landLinePhone.nationalNumber}"
                    countryCode:  "${landLinePhone.countryCode}"
                } 
                movilePhone: {
                    e164:  "${movilePhone.e164}"
                    nationalNumber:  "${movilePhone.nationalNumber}"
                    countryCode:  "${movilePhone.countryCode}"
                }
                email : "${email}"
                identification: "${identification}"
                identificationType: "${identificationType}"
                address: {
                    province: {
                        code: "${address.province.code}"
                        name: "${address.province.name}"
                    }
                    canton:  {
                        code: "${address.canton.code}"
                        name: "${address.canton.name}"
                    }
                    district:  {
                        code: "${address.district.code}"
                        name: "${address.district.name}"
                    }
                    neighborhood:  {
                        code: "${address.neighborhood.code}"
                        name: "${address.neighborhood.name}"
                    }
                    exactAddress: "${address.exactAddress}"
                }
                name : "${name.substring(0,100)}"
                lastName : "${name.substring(0,80)}"
                businessName : "${name.substring(0,80)}"
                tradeName : "${tradeName.substring(0,80)}"
                profilePicture : "${profilePicture}",
                ${exonet.length > 0 ? `exonet:[${currentExon}]`: ''}
            }
        ){
            success
            message
            description
            code
            data{
                _id
                IDUser
                landLinePhone{
                    e164
                    nationalNumber
                    countryCode
                }
                movilePhone{
                    e164
                    nationalNumber
                    countryCode
                }
                email
                clientType
                address{
                    province{
                      code
                      name
                    }
                   canton{
                      code
                      name
                    }
                    district{
                      code
                      name
                    }
                    district{
                      code
                      name                      
                    }
                  neighborhood{
                  	code
                    name
                  }
                    exactAddress
                }
                name
                lastName
                identificationType
                identification
                businessName
                tradeName
                societyType
                profilePicture
                ${exonet.length > 0 ? `exonet {
                    docNumber
                    exonerationPercentage
                    expireDate
                    cabysCodes
                    emisionDate
                }`: ''}
            }
        }
    }`
}

export function deleteClient(client : DeleteClientInput){
    return `mutation{
        deleteClient(
            deleteClientData : {
                clientID: "${client.clientID}"
            }
        ){
            success
            message
            description
            code
        }
    }`
}

export function getClients(){
    return `
    query{
        getUserClients
        {
            success
            message
            description
            code
            data{
                _id
                IDUser
                email
                clientType
                name
                lastName
                identificationType
                identification
                businessName
                tradeName
                societyType
                profilePicture
                address {
                    province{
                      name
                      code
                    }
                    district{
                      name
                      code
                    }
                    canton{
                      name
                      code
                    }
                    district{
                      name
                      code
                    }
                    neighborhood{
                        name
                        code
                      }
                  exactAddress
                }
                movilePhone {
                    e164
                    nationalNumber
                    countryCode
                }
                landLinePhone {
                    e164
                    nationalNumber
                    countryCode
                }
                exonet {
                    docNumber
                    expireDate
                    exonerationPercentage
                    cabysCodes
                    emisionDate
                    institutionName
                    docType{
                        code
                        description
                    }
                }
            }
        }
    }
    `
}