import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';

@Injectable()
export class AlertService {

  constructor(private alertController: AlertController, public toastController: ToastController) { 

  }


  showSimpleAlert(title,message,buttons,customClass): Promise<boolean>{
    return new Promise(async (resolve)=>{
      const alert = await this.alertController.create({
        backdropDismiss:false,
        cssClass: customClass,
        header: title,
        message: message,
        buttons: [
          {
          ...buttons[0],
          handler: () => {
              resolve(false);
            }
          }, {
          ...buttons[1],
          handler: () => {
              resolve(true);
            }
          }
        ],
      })
      await alert.present();
    });
  }


  showInputNumberAlert(title,buttons,customClass,message,inputs,index){
    return new Promise(async (resolve,reject)=>{
      const alert = await this.alertController.create({
        backdropDismiss:false,
        cssClass: customClass,
        header: title,
        message: message,
        inputs: inputs,
        buttons: [{
          ...buttons[0],
          handler: () => {
            reject(null);
          }
        }, {
          ...buttons[1],
          handler: (data) => {
            const discount = data.discount;
            if(discount <= inputs[index].min || discount > inputs[index].max){
              this.presentToast('Debe cumplir con el minimo y maximo permitido');
              return false;
            }else{
              resolve(data);
            }
          }
        }
      ],
      });
      await alert.present();
    });
  }

  showListAlert(title,buttons,customClass,array, selected){
    return new Promise(async (resolve,reject)=>{
      const list = this.createRadioList(array, selected);
      const alert = await this.alertController.create({
        backdropDismiss:false,
        cssClass: customClass,
        header: title,
        buttons: [{
            ...buttons[0],
            handler: () => {
              reject(false);
            }
          }, {
            ...buttons[1],
            handler: (data) => {
              
              resolve(data);
            }
          }
        ],
        inputs: list
      });
      await alert.present();
    })
  }


  createRadioList(array, selected){
    return array.map((e)=>{
      return {
        type: 'radio',
        label : e.label,
        value : e.value,
        checked : e.value === selected
      }
    })
  }


  async presentToast(text, color = "danger", duration= 5000) {
    const toast = await this.toastController.create({
      message: text,
      duration: duration,
      color: color,
      position: 'top'
    });
    toast.present();
  }


}
